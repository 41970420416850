@import '../../index.scss';

.otp_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .otp_container_logo {
    text-align: center;
  }

  .otp_wrapper {
    width: 400px;

    .otp_heading {
      text-align: center;
      font-size: 32px;
      line-height: 48px;
      font-weight: 500;
    }

    .sub_heading {
      width: 100%;
      height: 26px;
      font-family: 'Inter';
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      padding-top: 20px;
      padding-bottom: 20px;
    }

    .inputs {
      padding-top: 30px;
      padding-bottom: 30px;
      width: 100%;
      display: flex;
      // justify-content: center;
      align-items: center;
    }
  }
  .button {
    background: $gradientButtonColor;
  }
  .resend_code {
    width: 100%;
    display: flex;
    justify-content: center;
    color: $primaryLinkColor;
    padding-top: 20px;
    cursor: pointer;
  }
}
.otp_input {
  outline: none;
  border: 1px solid $inputHeadingColor;
  border-radius: 8px;
  width: 50px !important;
  height: 50px !important;
}
.otp_input:focus {
  border: 1px solid $activeTabColor;
  border-radius: 8px;
}
.otp_input_wrapper {
  outline: none;
  width: 100%;
  display: flex;
  // justify-content: center;
  align-items: center;
}
