@import '../../index.scss';
.candidate_container {
  .candidate_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .candidate_heading_and_back {
      display: flex;
      gap: 10px;
      height: 100%;
      align-items: center;
      .candidate_heading_text {
        font-family: 'Poppins';
        font-size: 20px;
        font-weight: 600;
      }
    }
    .candidate_header_buttons {
      display: flex;
      gap: 10px;
    }
  }
  .candidate_container1 {
    display: flex;
    justify-content: space-between;
    padding-top: 30px;
    gap: 30px;
    .candidate_candidateinfo_container {
      // width: 68%;
    }
    .candidate_flowchart_container {
      width: 200px;

      // margin-bottom:20px ;
      padding: 20px;
      background-color: $buttonTextColor;
      display: flex;
      flex-direction: column;
      align-items: center;
      box-shadow: #e3e6ec 0px 8px 24px;
      border-radius: 24px;
      height: 200px;
    }
    .candidate_dropdown_container {
      width: 20%;
      .candidate_dropdown_wrapper {
        padding-bottom: 20px;
      }
    }
  }
  .candidate_container2 {
    width: 60%;
    box-shadow: #e3e6ec 0px 8px 24px;
    border-radius: 24px;
    // margin-top: 30px;
    padding-bottom: 20px;
    min-height: 500px;
    .candidate_toggle_header {
      padding: 30px;
    }
    .candidate_resume_wrapper {
      // display: flex;
      // justify-content: center;
      // align-items: center;
      .candidate_footer_buttons {
        gap: 20px;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        // position: absolute;
        // bottom: 10px;
        padding-bottom: 10px;
        padding-right: 30px;
        padding-top: 20px;
        position: relative;
        //   background: linear-gradient(180deg, rgba(0, 0, 0, 0) 20.83%, rgba(0, 0, 0, 0.48) 100%);
      }
    }
    .candidate_document_container {
      padding: 20px;
      .candidate_document_header {
        display: flex;
        justify-content: space-between;
        padding-bottom: 20px;
        .candidate_document_text {
          font-family: 'Poppins';
          font-size: 14px;
          font-weight: 500;
        }
        .candidate_document_button {
          display: flex;
          gap: 10px;
          height: 100%;
          align-items: center;
          cursor: pointer;
          .candidate_document_upload_text {
            font-family: 'Poppins';
            font-size: 14px;
            font-weight: 500;
            color: $primaryLinkColor;
          }
        }
      }
      .candidate_attachment_container {
        display: flex;
        gap: 20px;
      }
    }
    .candidate_email_container {
      .candidate_email_dropdown_container {
        display: flex;
        justify-content: space-between;
        padding-bottom: 30px;
        .candidate_reply_button {
          padding-top: 20px;
        }
      }
    }

    .candidate_notes_container {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      .candidate_cards_component {
        width: 45%;
      }
      .candidate_add_notes_wrapper {
        width: 45%;
        background-color: $secondaryBtnColor;
        margin: 20px;
        border-radius: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        .candidate_add_notes {
          display: flex;
          gap: 20px;
          .candidate_add_text {
            font-family: 'Poppins';
            font-size: 14px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0px;
            text-align: left;
            color: $primaryLinkColor;
          }
        }
      }
    }
  }
  .candidate_input_heading {
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 500;
    padding-bottom: 8px;
    color: $inputHeadingColor;
  }
}
.candidate_back_icon {
  cursor: pointer;
}
.candidate_resume_image {
  width: 100%;
  height: 500px;
}

.candidate_resume_container {
  padding: 0 30px;
}
.candidate_back_icon_wrapper {
  height: 15px;
  width: 15px;
}

.candidate_circular_progress {
  // padding-bottom: 30px;
  width: 150px;
}
