@import '../../index.scss';
.integration_container {
  width: 100%;
  height: 100%;
  padding-left: 20px;
  box-shadow: #e3e6ec 0px 8px 24px;
  border-radius: 24px;
  margin-top: 10px;
}
.integration_wrapper {
  width: 100%;
  height: 100%;
}
.integration_heading {
  font-family: 'Poppins';
  font-size: 20px;
  font-weight: 600;
  padding: 20px 0;
}
.integrations_wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding-top: 20px;
  gap: 20px;
}
.google_integration_heading {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  padding-top: 10px;
}
.google_integrate_button_wrapper {
  padding-top: 15px;
  width: 200px;
}
.google_icon_wrapper {
  height: 64px;
  width: 64px;
}
.google_icon {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.google_integration_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.google_integration_disconnect {
  color: $iconBadgeBackground;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  padding-top: 10px;
  &:hover {
    cursor: pointer;
  }
}
//
.microsoft_integration_heading {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  padding-top: 10px;
}
.microsoft_integrate_button_wrapper {
  padding-top: 15px;
  width: 200px;
}
.microsoft_icon_wrapper {
  height: 64px;
  width: 64px;
}
.microsoft_icon {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.microsoft_integration_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.microsoft_integration_disconnect {
  color: $iconBadgeBackground;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  padding-top: 10px;
  &:hover {
    cursor: pointer;
  }
}
//@at-root

.email_integration_heading {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  padding-top: 10px;
}
.email_integrate_button_wrapper {
  padding-top: 15px;
  width: 200px;
}
.email_icon_wrapper {
  height: 64px;
  width: 64px;
}
.email_icon {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.email_integration_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.email_integration_disconnect {
  color: $iconBadgeBackground;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  padding-top: 10px;
  &:hover {
    cursor: pointer;
  }
}
.default_calendar_wrapper {
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  padding-top: 10px;
  &:hover {
    cursor: pointer;
  }
}
.default_calendar_container {
  .heading {
    font-family: 'Poppins';
    font-size: 20px;
    font-weight: 600;
    padding: 20px 0;
    margin-top: 20px;
  }
}
.default_calender_google{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap:10px;
  padding-bottom: 20px;
  &:hover {
    cursor: pointer;
  }
  .calendar_text{
    font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  }
}
.radio_btn{
  display: flex;
  justify-content: center;
  align-items: center;
}
.equest_icon_wrapper {
  height: 64px;
  width: 90px;
}
.equest_icon {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.equest_integration_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.equest_integration_disconnect {
  color: $iconBadgeBackground;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  padding-top: 10px;
  &:hover {
    cursor: pointer;
  }
}
.equest_integrate_button_wrapper {
  padding-top: 15px;
  width: 200px;
}
.equest_integration_heading {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  padding-top: 10px;
}
.integration_subscribe {
  padding-top: 30px;
  display: flex;
  gap: 5px;
  align-items: center;

  .subscribe_text {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    color: $grayText ;
  }

  .subscribe_link {
    margin-left: 5px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    color: $primaryBtnColor;
    text-decoration: underline;
    cursor: pointer;
  }
}