@import '../../index.scss';

.email_confirmation_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .email_confirmation_wrapper {
    width: 400px;
    .email_confirmation_container_logo {
      text-align: center;
    }

    .email_confirmation_heading {
      text-align: center;
      font-size: 32px;
      line-height: 48px;
      font-weight: 500;
    }
    .email_confirmation_content {
      font-family: 'Inter';
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0px;
      text-align: center;
      padding: 20px;
      .email_confirmation_mailid {
        text-align: center;
        font-family: 'Inter';
        font-size: 13px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0px;
        text-align: center;
      }
    }
    .button {
      font-family: 'Inter';
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 0px;
      text-align: center;
      background: $gradientButtonColor;
    }

    // .sub_heading {
    //   width: 100%;
    //   height: 26px;
    //   font-family: 'Inter';
    //   font-weight: 400;
    //   font-size: 14px;
    //   line-height: 24px;
    //   text-align: center;
    //   padding-top: 20px;
    //   padding-bottom: 20px;
    // }

    // .inputs {
    //   padding-top: 30px;
    //   padding-bottom: 30px;
    //   width: 100%;
    // }

    // .input_heading {
    //   color: $inputHeadingColor;
    //   font-size: 12px;
    //   font-weight: 500;
    //   padding-bottom: 15px;
    // }
  }

  // .back_to_login {
  //   width: 100%;
  //   display: flex;
  //   justify-content: center;
  //   color: $primaryLinkColor;
  //   padding-top: 20px;
  //   cursor: pointer;
  // }
}
