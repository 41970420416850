@import '../../index.scss';

.create_profile_container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 15px;
  height: 100vh;
  .create_profile_wrapper {
    width: 400px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .header {
    width: 580px;
    height: 48px;
    font-size: 32px;
    font-weight: 500;
    line-height: 48px;
    display: flex;
    justify-content: center;
    font-family: 'Poppins';
  }
  .headings {
    color: $inputHeadingColor;
    font-size: 12px;
    font-weight: 500;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding-top: 40px;
    padding-bottom: 10px;
    font-family: 'Inter';
  }
  .photo_and_button {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .upload_button {
    width: 264px;
    height: 56px;
    margin-top: 20px;
    font-size: 'Inter';
    background: $gradientButtonColor;
  }
  .full_name {
    height: 88px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    .first_name,
    .last_name {
      width: 192px;
    }
  }
  .info {
    width: 100%;
  }
  .buttons {
    width: 100%;
  }
  .linkedin_button,
  .facebook_button {
    margin-top: 15px;
    margin-bottom: 15px;
    font-family: 'Inter';
  }
}
.social_btn {
  background: $socialBtnColor;
}
.save_and_continue {
  margin-top: 35px;
  font-family: 'Inter';
  background: $gradientButtonColor;
}
.profile_picture {
  height: 101px;
  width: 101px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  object-fit: cover;
}
