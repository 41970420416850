@import '../../../index.scss';

.circular_progress_text {
  font-family: 'Inter';
  font-weight: 400;
  font-size: 10px;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.circular_progress_value {
  font-family: 'Poppins';
  font-weight: 700;
  font-size: 25px;
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $socialBtnColor;
}
