@import '../../index.scss';

.legend_details_wrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-top: 10px;
  .legend_details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
    .diversity_color {
      height: 20px;
      width: 20px;
    }
    .diversity_text {
      font-family: 'Inter';
      font-size: 10px;
      font-weight: 400;
      line-height: 32px;
    }
  }
}
