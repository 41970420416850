@import '../../index.scss';
.interview_container {
  .interview_header {
    display: flex;
    justify-content: space-between;
    .interview_heading_and_icon {
      display: flex;
      gap: 20px;
      height: 100%;
      align-items: center;
      .interview_header_text {
        font-family: 'Poppins';
        font-size: 20px;
        font-weight: 600;
      }
    }
  }
  .interview_wrapper {
    display: flex;
    padding-top: 20px;
    gap: 10px;
    justify-content: space-between;
    .interview_candidate_container {
      // width: 30%;
      .candidate_container {
        width: 100%;
      }
    }
    .interview_call_container {
      width: 45%;
    }
    .interview_dropdown_container {
      width: 25%;
      .interview_input_heading {
        font-family: 'Inter';
        font-size: 12px;
        font-weight: 500;
        padding-bottom: 10px;
        color: $inputHeadingColor;
      }
    }
  }
  .interview_bottom_container {
    width: 60%;
    // margin-top: 30px;
    box-shadow: #E3E6EC 0px 8px 24px;
    border-radius: 24px;
    padding: 30px;
    .interview_resume_container {
      position: relative;
      .interview_footer_buttons {
        gap: 20px;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        // position: absolute;
        // bottom: 10px;
        padding-bottom: 10px;
        padding-right: 30px;
        //   background: linear-gradient(180deg, rgba(0, 0, 0, 0) 20.83%, rgba(0, 0, 0, 0.48) 100%);
      }
    }
    .interview_subheading {
      font-family: 'Inter';
      font-size: 14px;
      font-weight: 600;
      padding-bottom: 10px;
    }
    .interview_rating_container {
      margin-bottom: 30px;
      width: 60%;
      .interview_rating_wrapper {
        display: flex;
        justify-content: space-between;
        padding-bottom: 15px;
        .interview_rating_heading {
          font-family: 'Inter';
          font-size: 13px;
          font-weight: 400;
          color: $inputPlaceholder;
        }
      }
    }
    .interview_feedback_text {
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0px;
      text-align: left;
    }
  }
}
.interview_screen_toggle_header {
  padding-bottom: 20px;
}
.interview_skills_content_container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}
.interview_feedback_container {
  width: 48%;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background-color: rgba(39, 198, 158, 0.2);
  color: $primaryLinkColor;
  flex &:hover {
    cursor: pointer;
  }
}
.interview_feedback_text_container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.feedback_icon_container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.interview_feedback_card_container {
  width: 48%;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  margin-bottom: 20px;
  &:hover {
    cursor: pointer;
  }
}
.interview_back_icon {
  cursor: pointer;
  height: 15px;
  width: 15px;
}
