@import '../../../index.scss';

.single_slider_container{

  .single_slider_wrapper {
  
  
    // margin-top: 10px;
    position: relative;
  
    .thumb_container {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 15px;
      height: 15px;
      margin-top: -2px;
      outline: 2px solid transparent;
      outline-offset: 2px;
      z-index: 20 !important;
  
      .thumb_name {
        position: absolute;
        top: 0;
        display: inline-block;
        padding: 2px 25px 2px 25px;
        margin-top: -47px;
        color: white;
        background: $gradientButtonColor;
        white-space: nowrap;
        border-radius: 20px;
        font-family: DM Sans;
        font-size: 16px;
        font-weight: 700;
        line-height: 28px;
  
      }
  
      .thumb_icon {
        width: 15px;
        height: 15px;
        background: #ffffff !important;
        border: 1px solid $borderColor;
        border-radius: 50%;
        cursor: pointer;
      }
    }
  
    .track_primary {
      height: 10px;
      border-radius: 9999px;
      cursor: pointer;
      background: $primarySliderColor;
      z-index: 10;
    }
  
    .track_secondary {
      background: $gradientButtonColor;
      height: 10px;
      border-radius: 9999px;
      cursor: pointer;
    }
  
  
  
  
  }
  .input_field_error {
    position: fixed;
    margin-top: 22px;
  }
}