@import '../../index.scss';

.feedback_modal_wrapper {
  width: 50% !important;
  border-radius: 16px;
}
.feedback_container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.feedback_wrapper {
  padding: 20px;
  width: 100%;
  border-radius: 24px;
}
.feedback_header {
  display: flex;
  justify-content: space-between;
}
.feedback_heading {
  font-family: 'Poppins';
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0px;
  padding-bottom: 20px;
}
.feedback_heading_skills {
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 500;
  line-height: 32px;
  padding: 10px 0;
}

.feedback_skills_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 70%;
}

.feedback_heading_behaviour {
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 500;
  line-height: 32px;
  padding-top: 20px;
  padding-bottom: 10px;
}

.feedback_heading_notes {
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 500;
  line-height: 32px;
  padding-top: 20px;
  padding-bottom: 10px;
}
.feedback_notes_container {
  display: flex;
  justify-content: center;
  align-items: center;
  // background-color: $inputBackground;
  // padding: 10px 20px;
  border-radius: 16px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}
.feedback_modal_submit_button_wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}

.feedback_modal_submit_button {
  width: 200px;
}
.feedback_modal_textarea {
  width: 100%;
}
