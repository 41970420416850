@import '../../../index.scss';

.list_component_container {
  // height: 100%;
  width: 100%;
  padding: 0 10px;

  .list_component_row {
    // height: 60px;
    display: flex;
    position: relative;
    justify-content: flex-start;
    align-items: center;
    padding: 13px 0;
    color: inputTextColor;
    border-bottom: 1px solid $inputBackground;

    .list_component_index_wrapper {
      width: 100px;

      .list_component_index {
        width: 50px;
        padding: 10px;
      }
    }

    .list_component_name_wrapper {
      // width: 40%;
      display: flex;
      justify-content: flex-start;
      padding: 0 5px;

      &:hover {
        cursor: pointer;
      }

      .list_component_name {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 20px;
        text-transform: capitalize;
        // padding-right: 20px;
      }
    }

    .email_wrapper {
      width: 600px;
    }

    .time_wrapper {
      width: 500px;
    }

    .list_component_action_btn_wrapper {

      // width: 20%;
      // width: 400px;
      .list_component_action_btn {
        width: 20%;
        height: 50px;
        // padding: 15px;
        border-radius: 16px;
        margin-right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $secondaryBtnColor;
        color: $inputTextColor;

        .list_component_icon-wrapper {
          padding-right: 7px;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}

.list_component_action_wrapper {
  // width: 100px;
  // display: flex;
  // justify-content: center;
  // width: 20%;
}

.list_component_actions_wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  // width: 250px;
}

.list_component_heading {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  color: $inputHeadingColor !important;
  padding: 0 !important;
}

a {
  text-decoration: none;
}

.list_component_loader {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.list_component_subtitle {
  font-family: 'Inter';
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 24px;
  color: $inputTextColor;
}

.list_component_action_btn {
  background: $secondaryBtnColor;
  color: $inputTextColor;
  // width: 45%;
}

.list_component_block {
  // width: 20% !important;
  // min-width: 20%;
}

.list_component_status_block {
  // padding: 6px;
  border-radius: 5px;
  // color: $buttonTextColor;
}

.empty_text {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Inter';
  // font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 24px;
  color: $inputTextColor;
  margin: 20px 0;
}

.list_component_load_button_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
}

.list_component_load_button {
  height: auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0px;
  background: transparent;
  padding: 0px;
  border-bottom: 1px solid $primaryBtnColor;
  color: $primaryBtnColor;
}

.empty_text {
  color: $primaryGrayColor;
}

.csv_download {
  position: absolute;
  right: 0px;
  top: 0px;
}

.csv_download_icon {
  height: 35px;
  width: 35px;
}