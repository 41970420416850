@import '../../index.scss';
.career_settings_container {
  width: 50%;
  // padding: 20px;
  border-radius: 24px;
  height: 100%;
  margin-top: 10px;
  .career_settings_wrapper {
    padding: 20px;
    box-shadow: #E3E6EC 0px 8px 24px;
    border-radius: 24px;

    .heading {
      width: 100%;
      font-family: 'Poppins';
      font-weight: 500;
      color: $inputTextColor;
      font-size: 20px;
      letter-spacing: -0.5px;
      text-align: left;
    }
    .input_heading {
      color: $inputHeadingColor;
      font-size: 12px;
      font-weight: 500;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      padding-top: 40px;
      padding-bottom: 10px;
      font-family: 'Inter';
    }
    .button {
      width: 400px;
      height: 56px;
      margin-top: 40px;
      background: $gradientButtonColor;
      border-radius: 15px;
      font-family: Inter;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
    }
  }
}
.career_settings_input_container{
  margin-top: 20px;
}