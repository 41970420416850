@import '../../index.scss';
.smtp_container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .smtp_wrapper {
    padding: 20px;
    width: 100%;
    border-radius: 24px;
    .smtp_header {
      display: flex;
      justify-content: space-between;
      .smtp_heading {
        font-family: 'Poppins';
        font-size: 24px;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: 0px;
        padding-bottom: 20px;
      }
    }
    .smtp_input_heading {
      color: $inputHeadingColor;
      font-size: 12px;
      margin-bottom: 10px;
      font-family: 'Inter';
      font-weight: 500;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      padding-top: 20px;
    }
    .smtp_name_container {
      display: flex;
      gap: 20px;
      .smtp_first_name {
        width: 50%;
      }
      .smtp_last_name {
        width: 50%;
      }
    }
  }
}
.smtp_modal_wrapper {
  border-radius: 16px;
  width: 30% !important;
}
