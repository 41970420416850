@import '../../../index.scss';

.tab_wrapper {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 16px;
  background-color: $inputBackground;
  padding: 0 5px;
  .tab_container {
    width: 45%;
    border-radius: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 85%;
    padding: 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    .tab_text {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $inputPlaceholder;
    }
  }
}
.tab_active {
  background: $activeTabColor;
}
.active_text {
  color: $buttonTextColor !important;
  font-size: 12px !important;
}
.tab_container:hover {
  cursor: pointer;
  transition: all 0.3s ease;
}
