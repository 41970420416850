.candidate_card_container {
  padding: 10px;
  width: 100%;
  border-radius: 24px;
  box-shadow: #e3e6ec 0px 8px 24px;
  padding: 11px;
  .candidate_card_header {
    // padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .candidate_card_heading {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    color: black;
  }
  .candidate_card_number {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 48px;
    color: black;
  }
  .candidate_card_line_chart {
    background-color: #ffffff;
    border-radius: 16px;
    // padding: 20px;
    margin-top: 15px;
  }
}
