@import url('../../../index.scss');

.pop_modal {
  width: 350px !important;
  border-radius: 16px;
}
.delete {
  background-color: white;
}
.pop_btn_cancel {
  background: #e4e4e4;
  color: black;
  min-width: 120px;
}
.pop_btn {
  min-width: 120px;
}
