.pdf_wrapper {
  position: relative;
  height:100%
}
.pdf_button_wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.pdf_btn {
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.decrement_btn {
  transform: rotate(90deg);
  cursor: pointer;
}
.increment_btn {
  transform: rotate(270deg);
  cursor: pointer;
}
.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 80% !important;
  height: 100% !important;
}
.react-pdf__Document{
  height:100%
}
.react-pdf__Page{
  height:100%
}