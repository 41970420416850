@import '../../index.scss';
.editcandidate_container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .editcandidate_wrapper {
    padding: 20px;
    width: 100%;
    border-radius: 24px;
    max-height: 700px;
    padding-bottom: 20px;
    overflow: auto;
    .editcandidate_header {
      display: flex;
      justify-content: space-between;
      .editcandidate_heading {
        font-family: 'Poppins';
        font-size: 24px;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: 0px;
        padding-bottom: 20px;
      }
    }
    .editcandidate_photo_button {
      display: flex;
      align-items: center;
      gap: 20px;
      .editcandidate_upload_button {
        width: 50%;
      }
    }
    .editcandidate_name_container {
      display: flex;
      gap: 20px;
      width: 100%;
      .editcandidate_name_wrapper {
        width: 50%;
      }
      .editcandidate_contact_wrapper {
        width: 25%;
      }
    }
    .editcandidate_relocation_wrapper {
      width: 50%;
    }
    .editcandidate_position_container {
      display: flex;
      gap: 20px;
      width: 100%;
      .editcandidate_postition_wrapper {
        width: 40%;
      }
     
    }
    .editcandidate_email_container {
      display: flex;
      width: 100%;
      gap: 20px;
      .editcandidate_email_wrapper {
        width: 40%;
      }
      .editcandidate_gender_wrapper {
        width: 25%;
      }
    }
    .editcandidate_location_container {
      width: 100%;
      // padding-bottom: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
    }
    .editcandidate_button_container {
      width: 100%;
      display: flex;
      justify-content: center;
      padding-top: 20px;
      .editcandidate_save_button {
        width: 30%;
      }
    }
  }
  .editcandidate_input_heading {
    color: $inputHeadingColor;
    font-size: 12px;
    margin-bottom: 10px;
    font-family: 'Inter';
    font-weight: 500;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding-top: 20px;
  }
}
.editcandidate_modal_wrapper {
  border-radius: 16px;
  width: 55% !important;
}
.candidate_upload_img {
  height: 70px;
  width: 70px;
  border-radius: 100%;
}
.editcandidate_gender_wrapper {
  width: 50%;
}
.editcandidate_compensation_wrapper {
  width: 50%;
}