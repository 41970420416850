@import '../../../index.scss';

.recruitment_funnel_container {
  width: 100%;
  .title {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #11142d;
    // padding-bottom: 10px;
  }
  .recruitment_funnel_block {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    .recruitment_funnel_image_block {
    //   width: 250px;
      img {
        // margin-top: 25px;
      }
    }
    .recruitment_text_block {
      width: 40%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    //   margin-top: 25px;
      padding-left: 8px;

      .recruitment_text_box {
        // margin: 0 0 15px 0;
        display: flex;
        flex-direction: column;
        // justify-content: space-between;
        width: 133px;
        height: 80px;
        position: relative;
        border-top: 1px solid $secondaryLightColor;
        .title {
          font-family: 'Inter', sans-serif;
          font-weight: 600;
          font-size: 8px;
        //   line-height: 18px;
          color: #808191;
        //   padding-top: 8px;
        }
        .number {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 10px;
          // line-height: 24px;
          color: #11142d;
        //   padding: 5px 0 0 0;
        }
      }
    }
  }
}
.recruitment_funnel_image {
  height: 200px;
//   object-fit: contain;
  width: 100%;
}
