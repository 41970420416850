@import '../../index.scss';
.main_hoc_container {
  display: flex;
  width: 100%;
  // height: 100%;
  .main_hoc_sidebar_wrapper {
    width: 230px;
    transition: width 0.3s ease;
  }
  .main_hoc_content_wrapper {
    height: 100%;
    width: calc(100vw - 230px);
    // width: 80%;
    transition: width 0.3s ease;
  }
  .main_hoc_navbar_wrapper {
    height: 10vh;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .main_hoc_navbar_agency_wrapper {
    height: 10vh;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.main_hoc_children_wrapper {
  overflow-y: auto;
  height: 90vh;
  padding: 0px 20px 20px 20px;
}
.main_hide_sidebar_wrapper {
  // width: 110px;
  width: 8%;
  transition: width 0.3s ease;
}
.main_hide_content_wrapper {
  height: 100%;
  width: 92%;
  transition: width 0.3s ease;
}
@media only screen and (min-width: 1680px) {
  .main_hide_sidebar_wrapper {
    width: 110px;
    // width: 6%;
    transition: width 0.3s ease;
  }
  .main_hide_content_wrapper {
    height: 100%;
    width: 93%;
    transition: width 0.3s ease;
  }
}
.req_orgs_dropdown_container{
  padding-left: 20px;
}