@import '../../index.scss';
.career_page_container {
  display: flex;
  align-items: center;
  justify-content: center;
  .career_page_wrapper {
    padding: 40px;
    width: 90%;
    margin-top: 40px;
    box-shadow: #e3e6ec 0px 8px 24px;
    border-radius: 24px;
    .career_header_container {
      width: 100%;
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 15px;
      .career_header_text {
        font-size: 30px;
        font-weight: 600;
        font-family: 'Poppins';
      }
    }
    .career_subheading {
      padding-bottom: 20px;
      font-family: 'Poppins';
      font-size: 24px;
      font-weight: 600;
      letter-spacing: -1px;
    }
    .career_dropdown_container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 0px 0px 30px 0px;
      border-bottom: 1px solid $secondaryBtnColor;
      .career_dropdown_wrapper {
        width: 30%;
        .career_dropdown_heading {
          font-family: 'Poppins';
          font-size: 16px;
          font-weight: 500;
          padding-bottom: 16px;
        }
      }
    }
    .career_body_container {
      .career_body_wrapper {
        border-bottom: 1px solid $secondaryBtnColor;
        // padding: 30px 0px 30px 0px;
        .career_body_heading {
          font-family: 'Poppins';
          font-size: 16px;
          font-weight: 500;
          padding: 16px 0px;
        }
        .career_key_and_value_container {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          width: 100%;
          padding: 12px 0px;
          .career_key_text {
            width: 18%;
            font-family: 'Inter';
            font-size: 13px;
            font-weight: 500;
            color: $primaryBlueColor;
            &:hover {
              cursor: pointer;
            }
          }
          .career_value_text {
            width: 75%;
            text-align: left;
            font-family: 'Inter';
            font-size: 14px;
            font-weight: 400;
          }
        }
      }
    }
  }
}

.career_header_icon {
  img {
    object-fit: contain;
  }
}
.career_body_heading {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 500;
  padding: 16px 0px;
}
// .career_page_container{
// .input {
//   background-color: $inputBackground;
//   height: 48px;
//   border: 0px;
//   border-radius: 8px;
//   outline: 0px;
//   padding: 10px;
//   font-size: 14px;
//   color: $inputTextColor;
// }
// .input_icon {
//   width: 15px;
//   height: 15px;
// }

// .focus {
//   outline: 2px solid #1d7bdf;
// }
// .set_bg {
//   background-color: $buttonTextColor !important;
//   color: $inputTextColor !important;
//   // font-weight: 600;
// }
// .input_field_error {
//   margin-top: 10px;
//   font-family: 'Inter';
//   font-weight: 400;
//   font-size: 12px;
//   color: $cancel;
// }
// input::-webkit-outer-spin-button,
// input::-webkit-inner-spin-button {
//   -webkit-appearance: none;
//   margin: 0;
// }
// }