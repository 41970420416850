@import '../../index.scss';
.email_template_container {
  width: 100%;
  padding: 20px;
  height: 100%;
  box-shadow: #E3E6EC 0px 8px 24px;
  border-radius: 24px;
  margin-top: 10px;
  .email_template_header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    align-items: center;
    width: 100%;
    .email_template_heading {
      width: 50%;
      font-family: 'Poppins';
      font-weight: 500;
      color: $inputTextColor;
      font-size: 20px;
      text-align: left;
    }
    .email_template_header_buttons {
      display: flex;
      justify-content: flex-end;
      align-items: 'center';
      width: 50%;
      .delete_btn {
        background: $secondaryBtnColor;
        width: 126px;
        color: $inputTextColor;
      }
      .save_btn {
        margin-left: 15px;
      }
    }
  }
  .email_template_wrapper {
    display: flex;
    justify-content: space-evenly;
    .email_template_sub_container {
      padding-top: 23px;
      width: 30%;
      max-height: 75%;
      overflow-y: auto;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: center;
      .email_template_input_heading {
        color: $inputHeadingColor;
        font-size: 12px;
        font-weight: 500;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        padding-bottom: 10px;
        font-family: 'Inter';
      }
      .email_template_add_container {
        display: flex;
        margin-bottom: 30px;
        .add_button {
          width: 30%;
          margin-left: 20px;
          background: $socialBtnColor;
        }
      }
    }
    .email_template_main_container {
      width: 70%;
      padding-left: 30px;
      // display: flex;
      // flex-direction: column;
      .email_template_name_container {
        padding-bottom: 30px;
      }
      .email_template_input_heading {
        color: $inputHeadingColor;
        font-size: 12px;
        font-weight: 500;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        padding-bottom: 10px;
        font-family: 'Inter';
      }
      .email_template_body_content {
        padding-top: 30px;
        .email_template_mail {
          font-family: 'Inter';
          font-size: 14px;
          font-weight: 600;
          line-height: 20px;
          letter-spacing: 0px;
        }
      }
      textarea {
        width: 100%;
        height: 200px;
        padding: 15px;
        border: none;
        border-radius: 8px;
        font-size: 13px;
        color: $inputPlaceholder;
        font-family: 'Poppins', sans-serif;
        background-color: $inputBackground;
        resize: none;
      }
      .active {
        font-weight: 600;
        color: black !important;
        background-color: $buttonTextColor;
        font-size: 14px;
      }
    }
  }
}
.email_template_save_button {
  width: 200px;
  margin-left: 15px;
}
.email_template_email_merge_fields {
  padding-top: 20px;
  width: 30%;
}
.email_template_load_more_btn {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.email_template_button {
  width: 100%;
}
