@import '../../../index.scss';
.navbar {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 10px;
  align-items: center;
  padding-right: 50px;
  &:hover {
    cursor: pointer;
  }
  .search {
    width: 500px;
  }

  .create_button {
    // width: 180px;
    transition: all 0.3s ease;
    .create_btn {
      background: $gradientButtonColor;
    }
  }
  .margin_right {
    margin-right: 20px;
  }
}
.navbar_logout_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.navbar_content {
  height: 90%;
}
.navbar_user_image {
  // height: 100px;
  object-fit: cover;
}
.navbar_logout_user_name {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: $primaryBtnColor;
  padding: 0px 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.navbar_logout_user_account {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  color: $inputHeadingColor;
  padding: 0 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.navbar_logout_icon {
  padding-left: 10px;
  &:hover {
    cursor: pointer;
  }
}
.navbar_profile_image {
  border-radius: 100%;
  object-fit: cover;
}
.navabr_profile_hover_container {
  background-color: $buttonTextColor;
  // width: 100%;
  position: absolute;
  top: 45px;
  left: -20px;
  box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 10px 20px;
  transition: all 0.3s ease;
  z-index: 100;
}
.navbar_profile_user_name {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: $primaryBtnColor;
  padding: 3px 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.navbar_profile_user_job_title {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: $secondaryLightText;
  padding: 3px 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.create_dropdown {
  position: absolute;
  padding: 15px;
  min-width: 250px;
  background-color: $buttonTextColor;
  margin-top: 10px;
  border-radius: 20px;
  // box-shadow: #E3E6EC 0px 8px 24px;
  box-shadow: #e3e6ec 0px 8px 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  transition: all 0.3s ease;
  z-index: 100;
}
// box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
// 0px 0px 9px 0px rgba(237, 234, 237, 0.54)
.create_dropdown_list_wrapper {
  font-size: 14px;
  font-family: 'Inter';
  font-weight: 600;
  padding: 10px;
  border-radius: 10px;
  width: 100%;
  &:hover {
    cursor: pointer;
    background-color: $inputBackground;
  }
}
.create_button_wrapper {
  position: relative;
}
.notification_icon {
  position: relative;
}
.navbar_notification_wrapper {
  width: 400px;
  background-color: $buttonTextColor;
  position: absolute;
  z-index: 100;
  // overflow: auto;
  border-radius: 16px;
  right: 0;
  margin-top: 20px;
  box-shadow: #e3e6ec 0px 8px 24px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 32px;
  flex-direction: column;
}
.notification_list_wrapper {
  width: 100%;
  height: 90%;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
}
.notification_list_element {
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 16px;
  margin-bottom: 10px;
  &:hover {
    cursor: pointer;
    background-color: $inputBackground;
  }
}
.notification_profile_wrapper {
  height: 45px;
  width: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    height: 45px;
    width: 100%;
  }
}
.notification_text_wrapper {
  width: 80%;
  display: flex;
  flex-direction: column;
  // text-align: center;
  font-family: 'Inter';
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
}
.notification_time_wrapper {
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  color: $secondaryLightText;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 20%;
}
.navbar_search_content {
  width: 500px;
  position: absolute;
  box-shadow: #e3e6ec 0px 8px 24px;
  border-radius: 24px;
  margin-top: 20px;
  z-index: 100;
  background-color: $buttonTextColor;
  display: flex;
  // justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 16px;
}
.navbar_search_list {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 12px 16px;
  border-radius: 12px;
  &:hover {
    cursor: pointer;
    background-color: $inputBackground;
  }
  .navabr_search_req_id {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.navbar_search_text {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  margin: 20px;
  color: $primaryGrayColor;
}
.search_load_more_wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  padding: 10px;
}
.notification_title {
  padding: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
}
.navbar_notification_list_container {
  height: 500px;
  overflow-y: auto;
}
.navbar_search_content_container {
  width: 100%;
}
.navbar_content_wrapper {
  overflow: auto;
  height: 500px;
}
.toggle_header {
  padding-bottom: 24px;
}
.navbar_search_req_title {
  font-family: 'Inter';
  font-size: 14px;
  line-height: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 20px;
  color: $inputPlaceholder;
}
.search_name_email_wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.navbar_search_list_candidate {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 12px 16px ;
  border-radius: 12px;
  gap: 16px;
  // margin: 10px 0px;
  &:hover {
    cursor: pointer;
    background-color: $inputBackground;
  }
  .navabr_search_req_id {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .navbar_search_text {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    margin: 20px;
    color: $primaryGrayColor;
  }
}
.navbar_email_wrapper {
  padding-right: 10px;
}
.notification_badge {
  position: absolute;
  background-color: $iconBadgeBackground;
  height: 22px;
  width: 22px;
  right: -10px;
  top: 0;
  border-radius: 100px;
  color: $buttonTextColor;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
}
.notification_icon_container {
  position: relative;
}
.subscription_vacancies{
padding: 0px 16px 0px 16px;
margin-right: 20px;
height: 46px;
align-items: center;
justify-content: center;
display: flex;
gap: 1px;
border-radius: 12px;
background: #F7F7F7;
;
.vacancies_heading{
font-family: Poppins;
font-size: 14px;
font-weight: 500;
color:  #11142D;
}
.vacancies_balance{
  margin-left: 5px;
  font-family: Poppins;
font-size: 16px;
font-weight: 500;
color:  #11142D;
}
.vacancies_total{
  margin-top:5px ;
  font-family: Poppins;
font-size: 12px;
font-weight: 500;
color:  #11142D;
}
}