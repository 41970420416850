@import '../../index.scss';
.resume_search_container {
  padding: 10px;
  box-shadow: #E3E6EC 0px 8px 24px;
  border-radius: 24px;
  padding: 20px;
  margin-top: 10px;
  .resume_search_heading {
    font-family: 'Poppins';
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0px;
  }
  .resume_search_wrapper {
    margin-top: 30px;
    width: 30%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    border-right: 1px solid $secondaryBtnColor;
    padding-right: 20px;

    .resume_search_input_heading {
      font-family: 'Inter';
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0px;
      color: $inputHeadingColor;
      padding-bottom: 10px;
    }
    .resume_search_currency_container {
      display: flex;
      width: 100%;
      gap: 10px;
      align-items: center;
      .resume_search_currency {
        width: 50%;
      }
      .resume_search_current_compensation {
        width: 50%;
      }
    }
    .resume_search_experience_header {
      display: flex;
      justify-content: space-between;
    }
    .resume_search_currentemp_wrapper {
      border-radius: 16px;
      width: 100%;
    }
    .resume_search_previousemp_wrapper {
      border-radius: 16px;
      width: 100%;
    }
  }
}

.resume_search_content_wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.resume_search_table_container {
  padding-left: 20px;
  width: 70%;
  max-height: 85vh;
  overflow-y: auto;
}
.resume_search_list_component_wrapper {
  width: 100%;
}
.resume_search_screen_container {
  display: flex;
}
.resume_search_table_check_box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 10px;
  margin: 20px 0px;
  border-bottom: 1px solid $secondaryLightColor;
  padding-bottom: 15px;
  &:hover {
    cursor: pointer;
  }
}
.resume_list_table_checkbox_header {
  font-family: 'Inter';
  font-weight: 600;
  font-size: 14px;
  padding-left: 20px;
}
.resume_search_experience_count {
  font-family: 'Inter';
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}
.resume_search_load_more_wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.resume_search_load_more_btn {
  background-color: transparent;
  color: $inputHeadingColor;
}
