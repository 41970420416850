@import '../../index.scss';

.work_locations_container {
  width: 100%;
  box-shadow: #E3E6EC 0px 8px 24px;
  border-radius: 24px;
  padding: 20px 0;
  margin-top: 10px;
  .work_location_agencies_container {
    width: 100%;
    padding: 0px;
    .agencies_heading {
      width: 100%;
      font-family: 'Poppins';
      font-weight: 500;
      color: $inputTextColor;
      font-size: 20px;
      letter-spacing: -0.5px;
      text-align: left;
      margin-bottom: 0px !important;
      height: 0px !important;
      padding: 10px;
      padding-left: 20px;
    }
    .agencies_container_wrapper {
      display: flex;
      flex-direction: row;
      width: 100%;
      // justify-content: center;
      // align-items: center;
      .input_block {
        width: 40%;
        // padding: 20px;
        .input_heading {
          color: $inputHeadingColor;
          font-size: 12px;
          font-weight: 500;
          width: 90%;
          display: flex;
          justify-content: flex-start;
          padding-top: 40px;
          padding-bottom: 10px;
          font-family: 'Inter';
        }
        .save_button {
          margin-top: 30px;
          .save {
            background: $gradientButtonColor;
            font-family: 'Inter';
            font-size: 14px;
            font-weight: 700;
            line-height: 20px;
            letter-spacing: 0px;
            text-align: center;
          }
        }
      }
      .table_block {
        width: 60%;
        padding-top: 10px;
        display: flex;
        overflow-y: auto;
        max-height: 80vh;
        justify-content: center;
        align-items: flex-start;
      }
    }
  }
}
.work_location_input_wrapper {
  border-right: 1px solid $secondaryBtnColor;
  padding: 20px;
}
.agencies_subscribe{
  width: 100%;
  font-family: 'Poppins';
  font-weight: 500;
  color: $inputTextColor;
  font-size: 20px;
  letter-spacing: -0.5px;
  text-align: left;
  margin-bottom: 0px !important;
  height: 0px !important;
  padding: 10px;
  padding-left: 20px;
  margin-top: 20px;
}