@import '../../index.scss';
.postjob_container {
  .postjob_subheading {
    font-family: 'Poppins';
    font-size: 14px;
    font-weight: 500;
    padding-bottom: 10px;
  }
  .postjob_input_heading {
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 500;
    color: $inputHeadingColor;
    padding-bottom: 10px;
  }
  .postjob_checkbox_wrapper {
    display: flex;
    align-items: center;
    gap: 5px;
    // margin-top: 20px;
    width: max-content;
    &:hover {
      cursor: pointer;
    }
  }
  .postjob_career_wrapper {
    display: flex;
    align-items: center;
    gap: 5px;
    width: max-content;
    &:hover {
      cursor: pointer;
    }
  }
  .postjob_header {
    display: flex;
    justify-content: space-between;
    .postjob_heading_and_icon {
      display: flex;
      gap: 20px;
      height: 100%;
      align-items: center;
      .postjob_back_icon {
        &:hover {
          cursor: pointer;
        }
      }
      .postjob_header_text {
        font-family: 'Poppins';
        font-size: 30px;
        font-weight: 600;
      }
    }
  }
  .postjob_wrapper {
    box-shadow: #e3e6ec 0px 8px 24px;
    margin-top: 20px;
    border-radius: 24px;
    padding: 20px;
    padding-bottom: 30px;
    .margin10 {
      margin: 10px;
    }
    .postjob_main_container {
      display: flex;
      gap: 20px;
      // height: 270px;
      .postjob_first_container {
        width: 31%;
        .postjob_channels_container {
          // padding-top: 20px;
          // margin-top: 20px;
        }
        .postjob_channels_wrapper {
          margin-top: 20px;
        }
      }
      .postjob_second_container {
        width: 47%;
        display: flex;
        flex-direction: column;
        gap: 20px;
        // margin-top: -23px;
        height: 100%;
        .company_details{
          textarea{
            height: 120px;
          }
        }
        .company_info_wrapper{
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .company_info {
          // gap: 20px;
          // display: flex;
          // justify-content: flex-end;
          // align-items: center;
          margin-right: 10px;
          padding-bottom: 10px;
          div{
            font-family: 'Inter';
            font-size: 12px;
            font-weight: 500;
          }
          .save_company_info {
            cursor: pointer;
            color: $primaryLinkColor;
          }
          .disable_save_info{
            color: $grayText;
          }
          .remove_company_info {
            color: $cancel;
            cursor: pointer;
          }
          .disable_remove_info{
            color: $grayText;
          }
        }
        .postjob_description_container {
          padding-bottom:10px ;
          // padding-top: 20px;
          textarea {
            background-color: $inputBackground;
            height: 380px;
            padding: 10px;
            border-radius: 8px;
            resize: none;
            width: 100%;
            border: 0px;
          }
        }
      }
      .postjob_third_container {
        width: 22%;
        display: flex;
        flex-direction: column;
        gap: 20px;
        .postjob_open_date_container {
          // padding-top: 10px;
          // padding-bottom: 10px;
        }
        .postjob_third_wrapper {
          // margin-top: 10px;
          // width: 25%;
          // padding: 10px;
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 20px;
        }
      }
    }
  }
  .postjob_preview_container {
      box-shadow: #e3e6ec 0px 8px 24px;
      margin-top: 30px;
      border-radius: 24px;
      padding: 20px;
    width: 100%;
    .postjob_preview_wrapper {
      background: $inputBackground;
      border-radius: 8px;
      padding: 20px;
      display: flex;
      gap: 10px;
      flex-direction: column;
      .postjob_desktop_preview_container {
        width: 100%;
        .postjob_desktop_preview_wrapper {
          background: white;
          border-radius: 16px;
          padding: 20px;
          max-height: 500px;
          overflow: auto;
          display: flex;
          justify-content: space-between;
          .postjob_desktop_preview_content {
            font-family: 'Inter';
            font-size: 13px;
            font-weight: 400;
            line-height: 18px;
          }
        }
        .postjob_desktop_hiring_container {
          margin-top: 20px;
          .postjob_desktop_hiring_content {
            margin-top: -3px;
            background: $inputBackground;
            border-bottom-left-radius: 16px;
            border-bottom-right-radius: 16px;
            padding: 10px;
          }
        }
      }
      .postjob_tablet_preview_container {
        width: 55%;
        padding-right: 20px;
        .postjob_tablet_preview_wrapper {
          background: $buttonTextColor;
          border-radius: 16px;
          padding: 20px;
          max-height: 500px;
          overflow: auto;
          .postjob_tablet_preview_content {
            font-family: 'Inter';
            font-size: 13px;
            font-weight: 400;
            line-height: 18px;
          }
        }
        .postjob_tablet_hiring_container {
          margin-top: 20px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .landing_recruiter_name {
            font-size: 14px;
            font-family: 'Poppins';
          }
          .postjob_tablet_hiring_content {
            margin-top: -3px;
            background: $inputBackground;
            border-bottom-left-radius: 16px;
            border-bottom-right-radius: 16px;
            padding: 10px;
          }
        }
      }
      .postjob_mobile_preview_container {
        width: 20%;
        .postjob_mobile_preview_wrapper {
          background: $buttonTextColor;
          border-radius: 16px;
          padding: 15px;
          max-height: 500px;
          overflow: auto;
          .postjob_mobile_preview_content {
            font-family: 'Inter';
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
          }
        }
        .postjob_mobile_hiring_container {
          margin-top: 20px;
          .postjob_mobile_hiring_content {
            margin-top: -3px;
            background: $inputBackground;
            border-bottom-left-radius: 16px;
            border-bottom-right-radius: 16px;
            padding: 10px;
          }
        }
      }
      .postjob_content_heading {
        font-family: 'Inter';
        font-size: 13px;
        font-weight: 600;
        padding-bottom: 10px;
      }
      .postjob_hiring_text {
        font-family: 'Inter';
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
        color: $inputPlaceholder;
      }
    }
  }
}

.post_job_upload_container {
  // width: 30%;
  padding-top: 20px;
  padding-left: 20px;
}
.post_job_upload_box {
  background-color: $inputBackground;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  padding: 20px;
  border-radius: 16px;
  width: 200px;
}

.post_job_req_desc_file {
}

.job_post_header {
  z-index: 1000;
  object-fit: contain;
  width: 200px;
}
.job_sub {
  font-family: 'Poppins';
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 8px;
  border-bottom: 1px solid $secondaryBtnColor;
}
.job_head {
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 600;
  // padding-bottom: px;
}
.job_post_mobile_hiring_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.job_post_mobile_image {
  height: 35px;
  width: 35px;
}
.landing_recruiter_name {
  font-size: 12px;
  font-family: 'Poppins';
  padding-bottom: 6px;
}
.job_post_content_container_desktop {
  border-right: 1px solid $secondaryBtnColor;
  padding-right: 30px;
  width: 60%;
  height: 100%;
}
.job_post_container_team {
  width: 40%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 40px;
}
.top_apply_btn {
  &:hover {
    cursor: default;
  }
}
.postjob_header_button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.postjob_video_holder video {
  border-radius: 8px !important;
}
.postjob_upload_container {
  // height: 100%;
}
#drag_area {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.upload_poc_doc {
  background: #f7f7f7;
  border: 1px dashed #d3d1d1;
  border-radius: 8px;
  height: 215px;
}
.postjob_channel_career_container {
  display: flex;
  // justify-content: space-between;
  .postjob_channels_container {
    width: 75%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
 
  .postjob_channel_select {
    max-width: 700px !important;
    width: 70%;
    min-width: 300px;
  }
}
.postjob_description_wrapper {
  .ql-container {
    height: 195px !important;
  }
}
.career_channel{
margin-bottom: 15px;
}
// .channel_edit{
//   cursor: none !important;
// }

.equest_container{
  display: flex;
  flex-direction: column;
}
.equest_channel{
  margin: 20px 0;
}

.prompt_container {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  z-index: 10;
  position: relative;
  align-items: center;
  width: 100%;
  
  .job_ad_container {
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin: 10px 0;
      width: 100%;
  
      .job_ad_generate {
        display: flex;
        align-items: center;
        justify-content: space-between;
  
      }
  
      .job_ad {
        display: flex;
        align-items: center;
  
        .job_ad_header {
          font-family: Inter;
          font-size: 12px;
          font-weight: 500;
          line-height: 16px;
          letter-spacing: 0px;
          text-align: left;
          color: $primaryDarkText;
  
        }
  
        .job_ad_text {
          border-radius: 8px;
          margin: 0 5px;
          background: $secondaryBtnColor;
          padding: 8px 16px;
          color: $primaryDarkText;
          font-family: Inter;
          font-size: 12px;
          font-weight: 500;
          cursor: pointer;
        }
  
        .active {
          background: $gradientButtonColor !important;
          color: $buttonTextColor;
        }
  
        .job_ad_eeo {
          margin-left: 5px;
          font-family: Inter;
          font-size: 14px;
          font-weight: 500;
          cursor: pointer;
          color: $primaryDarkText;
  
        }
      }
    }
  .job_desc {
    display: flex;
    align-items: center;
    // padding: 2px 5px;
    border-radius: 12px;
    gap: 3px;
    border: #D1D5DB 1px solid;
    cursor: pointer;
  }

  .prompt_name {
    font-family: Inter;
    font-size: 8px;
    font-weight: 500;
    padding: 2px 5px !important;
  }

  .job_desc_active {
    background: $gradientButtonColor;
  }

  .prompt_active {
    color: $buttonTextColor;
  }

  // .desc_length{
  //   width: 73px;
  // //   .dropdown_input_value{
  // //     padding: 0 !important;
  // //   }
  //   .css-1jqq78o-placeholder{
  //     color: $inputTextColor !important;
  //     font-family: 'Inter';
  //     font-weight: 500 !important;
  //     font-size: 8px !important;
  //     padding: 2px 5px !important;
  //   }
  //   .dropdown_single_value{
  //     color: $inputTextColor !important;
  //     font-family: 'Inter';
  //     font-weight: 500 !important;
  //     font-size: 8px !important;
  //     // padding: 0 5px !important;
  //   }
  // //   .dropdown_name_active{
  // //  color: $buttonTextColor !important;
  // //     font-family: 'Inter';
  // //     font-weight: 500 !important;
  // //     font-size: 12px !important;
  // //     padding: 0 5px !important;
  // //   }
  // //   .dropdown_wrappers_active{
  // //     border-radius: 16px !important;
  // //     border: #D1D5DB 1px solid !important;
  // //     background: $gradientButtonColor !important;
  // //     cursor: pointer;
  // //     // padding: 0 5px !important;
  // //     min-height: 30px !important;
  // //   }
  // //   .css-13cymwt-control{
  // //     padding: 0 5px !important;
  // //   }
  // .css-qbdosj-Input{
  //   margin: 0 !important;
  //   padding: 0 !important;
  // }
  //   .dropdown_control{
  //     border-radius: 16px !important;
  //     border: #D1D5DB 1px solid !important;
  //     background: $buttonTextColor !important;
  //     cursor: pointer;
  //     padding: 0   !important;
  //     min-height: 25px !important;
  //   }
  // }
  
  .generate_code {
    display: flex;
    gap: 5px;
    min-height: 25px !important;
    .loader_container{
      margin: 0px !important;
    }

    .cus_prompt_name {
      font-family: Inter;
      font-size: 8px;
      font-weight: 600;
      padding: 2px 10px;
      border-radius: 8px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      background: $secondaryBtnColor;
      cursor: pointer;
    }
    .desc_loader {
      width: 23px;
      height: 23px;
      position: relative;
     
    }
    .generate {
      border-radius: 8px;
      padding: 2px 6px;
      background: $primaryDarkColor;
      cursor: pointer;
      display: flex;
      gap: 3px;
      align-items: center;
      .generate_name {
        font-family: Inter;
        font-size: 10px;
        font-weight: 700;
        line-height: 20px;
        text-align: center;
        color: $buttonTextColor;
      }
    }
    .generate_disable {
      border-radius: 8px;
      padding: 2px 6px;
      background: $disabledGreyBgColor;
      cursor: default;
      display: flex;
      gap: 3px;
      align-items: center;

      .generate_name {
        font-family: Inter;
        font-size: 10px;
        font-weight: 700;
        line-height: 20px;
        text-align: center;
        color: $disabledGreyTextColor;
      }
    }
  }
}
.post_job_btn{
  width: 140px;
}
.invite_job_btn{
  width: 150px;
}