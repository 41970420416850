@import '../../../index.scss';

.upload_image_container {
  height: 101px;
  width: 101px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: $inputBackground;
  position: relative;
}
.upload_image_wrapper {
  height: 101px;
  width: 101px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: transparent;
  position: relative;
}
.camera_image {
  width: 18px;
  height: 18px;
}
.upload_box {
  display: none;
}
.upload_box_label_content {
  align-items: center;
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
}
.upload_close_wrapper {
  position: absolute;
  top: 5px;
  right: 5px;
  height: 20px;
  width: 20px;
  background-color: $inputHeadingColor;
  border-radius: 100px;
}
.upload_close_icon {
  height: 100%;
  width: 100%;
}
