@import '../../../index.scss';
.subscription_agency_container {
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 400px;
  border-radius: 16px;
  .subscription_wrapper {
    padding: 8px 16px;
    // padding: 20px;
    width: 100%;
    border-radius: 24px;
    .subscription_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 20px;
      .subscription_heading {
        font-family: 'Poppins';
        font-size: 24px;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: 0px;
        // padding-bottom: 20px;
      }
    }
    .subscription_subheading {
      font-family: 'Inter';
      font-size: 13px;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: 0px;
      text-align: left;
      padding-bottom: 10px;
    }
    .subscription_send_button{
        display: flex;
        justify-content: flex-end;
        padding-top: 30px;
    }
  }
}
.subscription_wrapper .react-responsive-modal-modal {
  border-radius: 16px;
  width: auto !important;
}

.customModalSubscription{
  border-radius: 16px;
}

.sub_modal_textarea{
  height: 100px;
}
.input_wrapper{
  display: flex;
  flex-direction: column;
  gap: 15px;
}