@import '../../index.scss';
.departments_container {
  padding: 20px;
  // height: 100%;
  box-shadow: #E3E6EC 0px 8px 24px;
  border-radius: 24px;
  margin-top: 10px;
  .departments_heading {
    margin-bottom: 40px;
    width: 100%;
    color: $inputTextColor !important;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
  }
  .departments_wrapper {
    .departments_input_heading {
      color: $inputHeadingColor;
      font-size: 12px;
      font-weight: 500;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      // padding-top: 40px;
      padding-bottom: 10px;
      font-family: 'Inter';
    }
    .departments_input_container {
      display: flex;
      flex-direction: column;
      padding-top: 5px;
      .departments_input_and_button {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .add_button {
          width: 17%;
          // margin-left: 20px;
          // margin-top: 20px;
        }
      }
    }
  }
}
.department_input_container {
  width: 80%;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.departments_table_container{
  padding-top: 20px;
  height: 500px;
  overflow: auto;
}