@import '../../index.scss';
.agencies_container {
  // height: 100%;
  width: 100%;
  border-radius: 24px;
  background-color: $buttonTextColor;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  box-shadow: #E3E6EC 0px 8px 24px;
  border-radius: 24px;
  padding: 20px;
  margin-top: 10px;
}

.agencies_heading {
  margin-bottom: 40px;
  width: 100%;
  color: $inputTextColor !important;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  padding-left: 15px;
}

.agencies_flex_container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.agency_wrapper {
  width: 100%;
  border-right: 1px solid $inputBackground;
  padding: 15px;
}

.agencies_input_wrapper {
  margin-bottom: 20px;
}
.agencies_button_wrapper {
  margin-top: 40px;
}
.agencies_label {
  height: 16px;
  // width: 115px;
  //styleName: caption/caption 2 - 12;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: $inputHeadingColor;
  margin-bottom: 15px;
}

.agencies_button {
  background: $gradientButtonColor;
}
.agency_flex_1 {
  display: flex;
  width: 100%;
  gap: 10px;

  justify-content: space-between;
  align-items: center;
}
.agency_flex_2 {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 10px;
  gap: 25px;
}
.agencies_commision_slab {
  // margin-top: 20px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
}

.agencies_commision_add_button {
  display: flex;
  width: 60%;
  justify-content: flex-start;
  align-items: center;
}

.agencies_commision_button {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  // background: $socialBtnColor;
}
.agencies_min_salary_wrapper {
  width: 100%;
}
.agencies_max_salary_wrapper {
  width: 100%;
}
.agencies_percentage_wrapper {
  width: 60%;
}
.agencies_navbutton_wrapper {
  width: 50%;
  margin-top: 30px;
}
.add_commission_lab {
  display: flex;
  flex-direction: column;
}

input[type='file']::file-selector-button {
  /* Add properties here */
  border: none;
  display: none;
}
.file_type {
  background-color: $inputBackground;
  // height: 50px;
  border: 0px;
  border-radius: 8px;
  outline: 0px;
  padding: 8px;
  font-size: 14px;
  color: $inputPlaceholder;
}
.file_type_active {
  background-color: $buttonTextColor !important;
  color: $inputTextColor !important;
  font-weight: 600;
}
.create_agency_wrapper {
  width: 40%;
}
.agency_table_wrapper {
  width: 60%;
  max-height: 90vh;
  overflow-y: auto;
}
.agency_screen_file_upload {
  background-color: $inputBackground;
  padding: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover {
    cursor: pointer;
  }
}
.agencies_subscribe {
  padding-top: 40px;
  display: flex;
  gap: 5px;
  align-items: center;

  .subscribe_text {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    color: $grayText ;
  }

  .subscribe_link {
    margin-left: 5px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    color: $primaryBtnColor;
    text-decoration: underline;
    cursor: pointer;
  }
}