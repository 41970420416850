@import '../../index.scss';

.signup_container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  padding: 20px 0;
  // height: 100vh;
  .signup_wrapper {
    width: 580px;
    // height: 736px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .welcome {
    font-size: 24px;
    width: 580px;
    height: 32px;
    text-align: center;
    font-weight: 500;
  }
  .create_an_account {
    width: 580px;
    height: 48px;
    font-size: 32px;
    text-align: center;
    font-weight: 500;
  }
  .inputs {
    width: 400px;
    .input_heading {
      color: $inputHeadingColor;
      font-size: 12px;
      margin-bottom: 10px;
      font-weight: 500;
      padding-top: 20px;
    }
    .hiring {
      width: 100%;
      margin-top: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    .terms_and_condition {
      width: 100%;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      padding-top: 20px;
      padding-bottom: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .text {
        padding-left: 10px;
      }
    }
    .terms_and_condition:hover {
      cursor: pointer;
    }
    .footer {
      font-size: 13px;
      line-height: 18px;
      text-align: center;
      font-weight: 600;
      padding-top: 20px;
      display: flex;
      justify-content: center;
      .login {
        color: $primaryLinkColor;
        cursor: pointer;
        padding-left: 10px;
      }
    }
  }
}
.sign_up_btn {
  background: $gradientButtonColor;
}
.checkbox_wrapper {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: $inputBackground;
}
.checked {
  background-color: $checkboxActive;
}
.policy_text {
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  font-weight: 600;
  padding-top: 10px;
  font-family: 'Inter';
}
