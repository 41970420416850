@import '../../index.scss';
.offercard_container {
  box-shadow: #E3E6EC 0px 8px 24px;
  border-radius: 24px;
  padding: 10px 20px;
  width: 100%;
  .offercard_header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    align-items: center;
    .offercard_heading_text {
      font-family: 'Poppins';
      font-size: 16px;
      font-weight: 500;
      color: $primaryLinkColor;
    }
    .offercard_header_dropdown {
      .offercard_dropdown .css-1s2u09g-control {
        background-color: transparent !important;
        // color: $primaryLinkColor !important;
      }
      .offercard_dropdown .css-14el2xx-placeholder {
        // color: $primaryLinkColor;
      }
    }
  }
  .offercard_wrapper {
    .offercard_value_container {
      display: flex;
      justify-content: space-between;
      padding-top: 10px;
      //   padding-bottom: 10px;
      //   padding: 10px 20px;
      .offercard_type {
        font-family: 'Poppins';
        font-size: 14px;
        font-weight: 500;
      }
      .offercard_value {
        font-family: 'Poppins';
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
}
.offercard_header_dropdown {
  min-width: 100px;
}
