@import '../../index.scss';
.attachment_file_block {
  display: flex;
  width: 100%;
  margin-right: 10px;
  .attachment_file_div {
    background-color: #f0f3f6;
    padding: 15px 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-radius: 16px;
    margin-bottom: 15px;
    img {
      width: 15px;
      height: 15px;
    }
    .file_block {
      width: 80%;
      padding-left: 10px;
      .title {
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: $inputTextColor;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .para {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: $primaryBtnColor;
      }
    }
  }
}
.attachment_file_icon_wrapper {
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.attachment_file_icon {
  width: 100%;
  height: 20px;
}
.attachment_file_close_icon {
  height: 10px !important;
  width: 10px !important;
  &:hover {
    cursor: pointer;
  }
}
