@import '../../../index.scss';

.react-time-picker__wrapper {
  border: none !important;
  background-color: $inputBackground;
  padding: 10px;
  border-radius: 8px !important;
  width: 100% !important;
  &:hover {
    cursor: pointer;
  }
}
.time_picker_wrapper {
  width: 100%;
}
.react-time-picker {
  width: 100%;
}
.react-time-picker__inputGroup__input:invalid {
  background: $inputBackground !important;
}
.react-calendar__tile--now {
  background: $inputBackground !important;
}
.react-calendar {
  width: 350px;
  max-width: 100%;
  background: white;
  border-radius: 10px;
  border: 1px solid $inputBackground;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}
.react-timerange-picker {
  width: 100%;
  border: none;
  &:hover {
    cursor: pointer;
  }
}
.react-timerange-picker__wrapper {
  border: none !important;
  padding: 10px;
  background-color: $inputBackground;
  border-radius: 8px;
  justify-content: space-between;
  flex-grow: 0 !important;
  flex-shrink: none;
  width: 100%;
}
.react-timerange-picker__inputGroup {
  min-width: 0px !important;
  flex-grow: 0 !important;
}
#react-time-range select {
  padding: 15px !important;
  background-color: #f4f4f4;
  border: none !important;
  border-radius: 5px;
  cursor: pointer;
}
#react-time-range {
  padding: 0 !important;
}
#react-time-range .error {
  margin-top: 10px;
  font-family: 'Inter';
  font-weight: 400;
  font-size: 12px;
  color: $cancel;
}
.react-calendar__tile--now {
  color: $primaryBtnColor !important;
}
