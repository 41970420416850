@import '../../../index.scss';
.candidates_block {
  // border: 1px solid $primaryCardColor;
  // margin: 20px 10px;
  background-color: $primaryCardColor;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // height: 100px;
  width: 80%;
  &:hover {
    cursor: pointer;
  }
  .candidate_image_block {
    width: 95%;
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    gap: 5px;
    align-items: flex-start;
    .candidate_image {
      width: 32px;
      height: 32px;
      object-fit: cover;
      border-radius: 100px;
    }
    .caption {
      font-family: 'Inter', sans-serif;
      font-weight: 600;
      font-size: 13px;
      line-height: 18px;
      color: $buttonTextColor;
      background-color: $iconBadgeBackground;
      padding: 7px 10px 5px 10px;
      vertical-align: middle;
      border-radius: 7px;
      height: 35px;
    }
  }
}
.candidate_profile_details {
  width: 80%;
  // padding: 10px 0px 5px 0px;
  .name {
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 5px;
    // width: 90%;
  }
  // .company_name {
  //   font-family: 'Inter', sans-serif;
  //   font-weight: 400;
  //   font-size: 13px;
  //   line-height: 24px;
  //   color: $inputPlaceholder;
  //   margin: 3px;
  //   white-space: nowrap;
  //   overflow: hidden;
  //   text-overflow: ellipsis;
  //   text-align: center;
  // }
}
.category_status_wrapper {
  width: 100%;
  padding: 5px;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  background-color: $checkboxActive;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  color: $buttonTextColor;
  font-size: 12px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}
.education,
.company_name {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  color: $inputPlaceholder;
  margin: 3px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.candidate_status_content {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 8px;
}
.image_name_wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 80%;
}
.candidate_status_content {
  padding-right: 3px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 3px;
  border-radius: 4px;
  color: $buttonTextColor;
  // width: 35%;
}
.score_percentage {
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 10px;
  padding: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  min-width: 22px
}
.score_match {
  font-family: 'Inter', sans-serif;
  font-size: 10px;
}
.kanban_board_checkbox_wrapper {
  width: 15%;
}
.agency_tag {
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 10px;
  padding: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  color: #1d7bdf;
  background-color: #E8F2FC;
  min-width: 22px
}