@import '../../index.scss';
.new_job_container {
  padding: 20px;
  margin-bottom: 100px;
  .new_job_header {
    display: flex;
    padding-bottom: 30px;
    justify-content: space-between;
    align-items: center;
    .new_job_header_text {
      font-family: 'Poppins';
      font-size: 30px;
      font-weight: 600;
      line-height: 72px;
      letter-spacing: -1px;
      text-align: left;
      width: 70%;
    }
    .new_job_header_buttons {
      display: flex;
      gap: 10px;
      padding-top: 20px;
      .header_button {
        width: 126px;
      }
      .gray_button {
        background: $secondaryBtnColor;
        color: $primaryDarkColor;
      }
    }
  }
  .new_job_wrapper {
    // box-shadow: #E3E6EC 0px 8px 24px;
    // border-radius: 24px;
    // padding: 20px;
  }
  .new_job_heading {
    font-family: 'Poppins';
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0px;
    margin-bottom: 15px;
  }
  .new_job_title_container {
    display: flex;
    width: 100%;
    padding-top: 30px;
    gap: 16px;
    justify-content: space-between;
    align-items: center;
    .new_job_job_title {
      width: 30%;
    }
    .new_job_input_heading {
      font-family: 'Inter';
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0px;
      padding-bottom: 10px;
      color: $inputHeadingColor;
    }
  }
  .new_job_location_container {
    display: flex;
    width: 100%;
    gap: 16px;
    justify-content: flex-start;
    align-items: center;
    padding-top: 30px;
    .new_job_input_heading {
      font-family: 'Inter';
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0px;
      padding-bottom: 10px;
      color: $inputHeadingColor;
    }

    .new_job_job_location {
      width: 15%;
    }
    .new_job_work_location {
      width: 15%;
    }
  }
  .new_job_vacancies_container {
    display: flex;
    gap: 16px;
    padding-top: 30px;
    .new_job_input_heading {
      font-family: 'Inter';
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0px;
      padding-bottom: 10px;
      color: $inputHeadingColor;
    }
    .new_job_numberof_vacancies {
      width: 25%;
    }
    .new_job_headcount_type {
      width: 30%;
    }
    .new_job_bonus_payout {
      width: 25%;
    }
    .new_job_percentage {
      width: 25%;
      justify-content: flex-end;
      .percentage {
        // visibility: show;
      }
    }
  }
  .new_job_client_container{
    display: flex;
    gap: 16px;
    align-items: center;
    padding-top: 30px;
    .new_job_input_heading {
      font-family: 'Inter';
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0px;
      padding-bottom: 10px;
      color: $inputHeadingColor;
    }
    .new_job_poc {
      width: 25%;
    }
    .new_job_account_manager {
      width: 35%;
    }
    .new_job_client {
      width: 20%;
    }
    .new_job_payment_terms {
      width: 20%;
    }
  }
  .new_job_remote_container {
    display: flex;
    gap: 16px;
    padding-top: 30px;
    .new_job_input_heading {
      font-family: 'Inter';
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0px;
      padding-bottom: 10px;
      color: $inputHeadingColor;
    }
    .new_job_remote_working {
      width: 25%;
    }
    .new_job_target_date {
      width: 25%;
    }
    .new_job_min_experience {
      width: 25%;
    }
    .new_job_max_experience {
      width: 25%;
    }
  }
  .new_job_upload_container {
    width: 100%;
    padding-top: 30px;
    padding-bottom: 40px;
    .new_job_upload_wrapper {
      width: 25%;
      .new_job_input_heading {
        font-family: 'Inter';
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0px;
        padding-bottom: 10px;
        color: $inputHeadingColor;
      }
    }
  }
  .new_job_description_wrapper {
    display: flex;
    padding-bottom: 20px;
    align-items: flex-start;
    width: 50%;
    justify-content: space-between;
    .new_job_description_editor {
      width: 47%;
    }
    .new_job_description_template {
      .new_job_templates_heading {
        font-family: 'Inter';
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0px;
        padding-bottom: 20px;
      }
      .new_job_tips_container {
        background: $secondaryBtnColor;
        border-radius: 8px;
        padding: 20px;
        height: 240px;
        overflow-y: auto;
        // margin-top: 20px;
        .new_job_tips_wrapper {
          // width: 80%;
          .margin_bottom {
            margin-bottom: 20px;
          }
        }
      }
    }
  }
  .new_job_skills_container {
    padding: 15px 0;
    .new_job_skills_wrapper {
      // padding-top: 15px;
      display: flex;
      // background: $secondaryBtnColor;
      border-radius: 16px;
    }
  }

  .new_job_behaviours_container {
    // padding-top: 20px;
    .new_job_behaviours_wrapper {
      display: flex;
      padding-top: 15px;
      // background: $secondaryBtnColor;
      border-radius: 16px;
    }
  }
}
.new_job_job_type {
  width: 15%;
}
.new_job_salary_min {
  width: 10%;
}
.new_job_work_location {
  width: 40% !important;
}
.new_job_upload_box {
  width: 100%;
  background-color: $inputBackground;
  padding: 15px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  &:hover {
    cursor: pointer;
  }
  // .job_req-desc_file{
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  // }
}
.new_job_job_department {
  width: 20%;
}
.new_job_job_business_unit {
  width: 20%;
}
.new_job_description_container {
  padding-top: 20px;
}
.gender_heading {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  padding-left: 5px;
}
.gender_title {
  font-family: 'Poppins';
  // font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  padding-top: 10px;
}
.gender_coded_words {
  padding-top: 10px;
  padding-right: 5px;
}
.new_job_description_text {
  font-size: 14px;
  font-family: 'Poppins';
  font-weight: 400;
}
.req_btn_wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.header_button {
  margin-left: 10px;
}
.gray_button {
  background: $secondaryBtnColor;
  color: $primaryDarkColor;
}
.new_job_numberof_vacancies {
  width: 20%;
}
.new_job_remote_working {
  width: 20%;
}
.new_job_target_date {
  width: 20%;
}
.new_job_min_experience {
  width: 15%;
}
.new_job_max_experience {
  width: 15%;
}
// box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
.new_job_details_container {
  box-shadow: #e3e6ec 0px 8px 24px;
  border-radius: 24px;
  padding: 20px;
}
.new_job_description_container {
  box-shadow: #e3e6ec 0px 8px 24px;
  border-radius: 24px;
  padding: 20px;
  margin-top: 32px;
}
.hiring_team_wrapper {
  box-shadow: #e3e6ec 0px 8px 24px;
  border-radius: 24px;
  padding: 20px;
  margin: 32px 0;
}
.new_job_salary_currency {
  width: 10%;
}
.new_job_type_wrapper {
  width: 20%;
}
.job_description_gender_decoder_wrapper {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
}
.job_description_editor {
  width: 50%;
}
.approval_header {
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
}

.approval_team_wrapper {
  width: 80%;
}

.approval_team_header {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
}

.approval_title {
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
}

.remove_icon {
  margin-right: 50px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  box-shadow: #e3e6ec 0px 8px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.approval_team_list {
  margin: 10px 0px 15px 0;
  max-height: 283px;
  overflow-y: scroll;
}

.create_approval_container {
  box-shadow: #e3e6ec 0px 8px 24px;
  border-radius: 24px;
  padding: 20px;
  margin: 32px 0;
}
.approval_type_title{
font-family: Inter;
font-size: 14px;
font-weight: 400;
line-height: 24px;
letter-spacing: 0px;
text-align: left;

}
.draft_btn {
  display: flex;
  width: 300px;
  justify-content: space-between;

  .save_draft_btn {
    width: 150px;
  }
}