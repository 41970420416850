@import '../../index.scss';
.recruitment_container {
  width: 100%;
  // padding: 0px 20px;
  .recruitment_heading_text {
    font-family: 'Poppins';
    font-size: 20px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    text-align: left;
  }
    .recruitment_search_container {
      border-radius: 24px;
      box-shadow: #e3e6ec 0px 8px 24px;
      padding: 20px;
      margin: 20px 0;
    }
  .recruitment_dropdown_container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    width: 100%;
    margin-top: 20px;
    .recruitment_dropdown_wrapper {
      width: 33%;
      .recruitment_dropdown_label {
        font-family: 'Poppins';
        font-size: 14px;
        font-weight: 600;
        padding-bottom: 10px;
        text-align: left;
      }
      .recruitment_dropdown {
        width: 100%;
      }
    }
  }
  .recruitment_dropdown_container2 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 30px;
    width: 75%;
    margin-bottom: 20px;
    .recruitment_dropdown_wrapper {
      width: 50%;
      .recruitment_dropdown_label {
        font-family: 'Poppins';
        font-size: 16px;
        font-weight: 600;
        padding-bottom: 10px;
        text-align: left;
      }
      .recruitment_dropdown {
        width: 100%;
      }
    }
  }
  .recruitment_wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    .recruitment_main_wrapper {
      width: 66%;
      .recruitment_source_container {
        border-radius: 24px;
        box-shadow: #e3e6ec 0px 8px 24px;
        padding: 20px;
      }
      .recruitment_department_container {
        border-radius: 24px;
        box-shadow: #e3e6ec 0px 8px 24px;
        padding: 20px;
        margin-bottom: 20px;
        margin-top: 20px;
      }
      .recruitment_aging_container {
        border-radius: 24px;
        box-shadow: #e3e6ec 0px 8px 24px;
        padding: 20px;
        margin-bottom: 20px;
        margin-top: 20px;
      }
    }
    .recruitment_side_wrapper {
      width: 30%;
      .recruitment_funnel_container {
        // border-radius: 24px;
        // box-shadow: #e3e6ec 0px 8px 24px;
        // padding: 20px;
        margin-bottom: 30px;
      }

      .recruitment_diversity_container {
        margin-top: 30px;
        .recruitment_diversity_wrapper {
          display: flex;
          justify-content: space-around;
          align-items: center;
          .recruitment_diversity_image {
            width: 10%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .recruitment_diversity_chart {
            border: 1px solid $gradientButtonColor;
            background-color: #27c69e;
            height: 6px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
  .recruitment_subheadings {
    font-family: 'Poppins';
    font-size: 18px;
    font-weight: 500;
    padding-bottom: 20px;
  }
}
.recruitment_diversity_chart_container {
  // display: flex;
  // justify-content: space-between;
  // background-color: #27c69e;
  width: 100%;
}
.recruitment_diversity_chart {
  width: 50%;
  border: 1px solid $gradientButtonColor;
  background-color: #27c69e;
  height: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.recruitment_diversity_chart_female {
  border: 1px solid $gradientButtonColor;
  width: 50%;
  background-color: $activeTabColor;
  height: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.recruitment_date_wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
}
.recruitment_date_dropdown_wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  margin-bottom: 35px;
}
.recruitment_dropdown_label {
  padding-bottom: 5px;
  font-size: 14px;
  font-family: 'Poppins';
  font-weight: 600;
  padding-bottom: 10px;
  text-align: left;
}
.recruitment_department_dropdown_wrapper {
  width: 25%;
}
.recruitment_source_header {
  display: flex;
  justify-content: space-around;
  border: 1px solid $secondaryBtnColor;
  border-radius: 16px;
  margin-bottom: 20px;
  .recruitment_source_card {
    width: 100%;
    border-right: 1px solid $secondaryBtnColor;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 0px;
    .recruitment_source_time_imagetext {
      display: flex;
      padding-bottom: 20px;
      gap: 10px;
    }
    .recruitment_source_time_text {
      font-family: 'Inter';
      font-size: 12px;
      font-weight: 500;
      color: $inputPlaceholder;
    }
    .recruitment_source_card_text {
      font-family: 'Poppins';
      font-size: 16px;
      font-weight: 500;
    }
  }
  .no_border {
    border-right: 0px;
  }
}
.recruitment_funnel_image {
  height: 200px;
  margin-top: 0px !important;
}
.recruitment_funnel_textbox {
  height: 40px !important;
}

.recruitment_source_container {
  position: relative;
  width: 32%;
  // height: 370px;
  border-radius: 24px;
  box-shadow: #e3e6ec 0px 8px 24px;
  padding: 20px;
}
.recruitment_department_container {
  border-radius: 24px;
  box-shadow: #e3e6ec 0px 8px 24px;
  padding: 20px;
  // height: 370px;
  margin-bottom: 20px;
}
.recruitment_aging_container {
  border-radius: 24px;
  position: relative;
  width: 32%;
  // height: 370px;
  box-shadow: #e3e6ec 0px 8px 24px;
  padding: 20px;
  // margin-bottom: 20px;
}
.recruitment_unattendend_container {
  // margin-bottom: 20px;
  border-radius: 24px;
  position: relative;
  width: 32%;
  // height: 370px;
}
.recruitment_chart_wrapper {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  flex-wrap: wrap;
}
.recruitment_diversity_container {
  border-radius: 24px;
  position: relative;
  width: 32%;
  box-shadow: #e3e6ec 0px 8px 24px;
  padding: 20px;
}
.recruitment_requisitions_container {
  border-radius: 24px;
  position: relative;
  width: 32%;
  box-shadow: #e3e6ec 0px 8px 24px;
  padding: 20px;
}
.recruitment_candidates_container {
  border-radius: 24px;
  position: relative;
  width: 32%;
  box-shadow: #e3e6ec 0px 8px 24px;
  padding: 20px;
}
.recruitment_export_wrapper{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap:20px;
  padding-bottom: 20px;
  .export_btn_wrapper{
    width:250px
  }
}
// .chartjs-render-monitor{
// height: 210px!important;
// display: flex;
// }
.recruitment_agency_wrapper {
  width:50%
}