@import '../../../index.scss';
.invite_agency_container {
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 400px;
  border-radius: 16px;
  .invite_wrapper {
    padding: 12px;
    width: 100%;
    border-radius: 24px;
    .invite_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 20px;
      .invite_heading {
        font-family: 'Poppins';
        font-size: 24px;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: 0px;
        // padding-bottom: 20px;
      }
    }
    .invite_subheading {
      font-family: 'Inter';
      font-size: 13px;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: 0px;
      text-align: left;
      padding-bottom: 10px;
    }
    .invite_send_button{
        display: flex;
        justify-content: center;
        padding-top: 30px;
    }
  }
}
.invite_wrapper .react-responsive-modal-modal {
  border-radius: 16px;
  width: auto !important;
}

.inviteModalAgency{
  width: 400px;
  // height: 400px;
  border-radius: 16px;
}