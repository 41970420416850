@import '../../index.scss';
.new_offer_container {
  .new_offer_header {
    display: flex;
    padding-bottom: 30px;
    .new_offer_header_wrapper {
      display: flex;
      width: 70%;
      height: 100%;
      align-items: center;
      .new_offer_header_text {
        font-family: 'Poppins';
        font-size: 30px;
        font-weight: 600;
        padding-left: 20px;
      }
    }
    .new_offer_header_buttons {
      display: flex;
      gap: 10px;
      padding-top: 15px;
      .header_button {
        width: max-content;
      }
      .gray_button {
        background: $secondaryBtnColor;
        color: $primaryDarkColor;
      }
    }
  }

  .new_offer_container1 {
    display: flex;
    justify-content: space-between;
    .new_offer_details_container {
      width: 55%;
      border-radius: 24px;
      box-shadow: #E3E6EC 0px 8px 24px;
      padding: 20px;
      .new_offer_details_wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
        .new_offer_details_content {
          min-width: 170px;
          max-width: max-content;
          .new_offer_details_heading {
            font-family: 'Poppins';
            font-size: 14px;
            font-weight: 500;
          }
          .new_offer_details_value {
            font-family: 'Inter';
            font-size: 14px;
            font-weight: 400;
          }
        }
        .new_offer_salary_container {
          width: 100%;
          display: flex;
          padding-top: 20px;
          gap: 20px;
          .new_offer_input_heading {
            font-family: 'Inter';
            font-size: 12px;
            font-weight: 500;
            color: $inputHeadingColor;
            padding-bottom: 10px;
          }
        }
      }
    }
    .new_offer_candidateinfo_container {
      width: 40%;
    }
  }
  .new_offer_container2 {
    padding: 20px;
    border-radius: 24px;
    box-shadow: #E3E6EC 0px 8px 24px;
    margin-top: 30px;
    .new_offer_heading {
      font-family: 'Poppins';
      font-size: 14px;
      font-weight: 500;
    }
    .new_offer_description_text {
      font-family: 'Inter';
      font-size: 14px;
      font-weight: 400;
    }
    .new_offer_letter_wrapper {
      display: flex;
      padding-top: 20px;
      padding-bottom: 20px;
      align-items: flex-start;
      justify-content: space-between;
      gap: 20px;

      .new_offer_template {
        width: 47%;
        .new_offer_templates_heading {
          font-family: 'Inter';
          font-size: 14px;
          font-weight: 600;
          padding-bottom: 20px;
        }
        .new_offer_tips_container {
          background: $secondaryBtnColor;
          border-radius: 8px;
          padding: 20px;
          margin-top: 20px;
          .new_offer_tips_wrapper {
            width: 80%;
            .margin_bottom {
              margin-bottom: 20px;
            }
          }
        }
      }
    }
  }
  .new_offer_subheading {
    font-family: 'Poppins';
    font-size: 14px;
    font-weight: 500;
    padding-bottom: 20px;
    width: 100%;
  }
}

