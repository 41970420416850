@import '../../../index.scss';

.admin_toggle_header_container {
  display: flex;
  width: 100%;
  gap: 10px;
  justify-content: flex-start;

  &:hover {
    cursor: pointer;
  }

  .toggle_header_wrapper {
    padding-bottom: 10px;
    font-family: 'Inter';
    font-size: 18px;
    font-weight: 600;
    margin-right: 25px;
    color: #B2B3BD;


  }

  .admin_toggle_header_active {
    background: linear-gradient(100.12deg, #27C69E -22.5%, #1C7BDF 122.07%);
    // position: absolute; 
    // top: 40%; 
    // left: 40%; 
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    transition: 20px 2s;

  }

  .line {
    width: 85%;
    height: 2px;
    background: #B2B3BD;
    border-radius: 8px;
  }

  .line_active {
    background: linear-gradient(100.12deg, #27C69E -22.5%, #1C7BDF 122.07%) !important;
  }

}