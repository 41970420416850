@import '../../index.scss';
.client_container {
  // height: 100%;
  width: 100%;
  border-radius: 24px;
  background-color: $buttonTextColor;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  box-shadow: #e3e6ec 0px 8px 24px;
  border-radius: 24px;
  padding: 20px;
  margin-top: 10px;


.client_heading {
  margin-bottom: 40px;
  width: 100%;
  color: $inputTextColor !important;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  padding-left: 15px;
}

.client_flex_container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.client_wrapper {
  width: 100%;
  border-right: 1px solid $inputBackground;
  padding: 15px;
}

.client_input_wrapper {
  margin-bottom: 20px;
  width: 100%;

}
.client_button_wrapper {
  margin-top: 40px;
}
.client_label {
  height: 16px;
  // width: 115px;
  //styleName: caption/caption 2 - 12;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: $inputHeadingColor;
  margin-bottom: 15px;
}
.client_button {
  background: $gradientButtonColor;
}
.create_client_wrapper {
  width: 40%;
}
.client_table_wrapper {
  width: 60%;
  max-height: 90vh;
  overflow-y: auto;
}
.poc_container{
  max-height:470px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
}
.poc_wrapper{
padding:0 8px 0 4px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  margin: 10px 0 20px 0;
  gap: 18px;
}

.poc_name{
width: 45%;
}
.poc_email{
  width: 45%;
  }
  .poc_nav_button_wrapper{
    width: 10%;
    margin-top:24px ;
  }
  .poc_label {
    height: 16px;
    // width: 115px;
    //styleName: caption/caption 2 - 12;
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
    color: $primaryDarkColor;
    margin-bottom: 15px;
  }
  .poc_add_button{
    width:60%;
    margin: 20px 0;
  }
}