@import '../../../index.scss';
.toggle_header_container {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  .toggle_header_wrapper {
    padding-bottom: 10px;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 600;
    margin-right: 25px;
    &:hover {
      cursor: pointer;
    }
  }
}
.toggle_header_active {
  border-bottom: 3px solid $activeTabColor;
}
