@import '../../../index.scss';

.org_modal_container {
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  border-radius: 16px;

  .org_modal_wrapper {
    width: 100%;
    height: 100%;
    border-radius: 24px;

    .invite_header {
      display: flex;
      justify-content: space-between;
      //styleName: headline/h4 - 32;
      font-family: Poppins;
      font-size: 28px;
      font-weight: 500;
      letter-spacing: -0.5px;
      text-align: left;
      margin-bottom: 15px;

    }

    .invite_heading {
      //styleName: headline/h6 - 18;
      font-family: Poppins;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0px;
      text-align: left;
      color: $primaryGrayColor;
    }

    .count_wrapper {
      margin-top: 34px;

      .single_slider_container .thumb_container .thumb_name {
        font-size: 12px;
        padding: 1.5px 18px 1.5px 18px;
        border-radius: 16px;
        margin-top: -33px;
      }
    }



    .invite_subheading {
      margin: 10px 0;


      font-family: Inter;
      font-size: 12px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0px;
      text-align: left;


    }

    .invite_send_button {
      display: flex;
      justify-content: flex-end;
      gap: 10px;
      padding-top: 60px;
    }

    .send_invite_button {
      width: 106px;
    }

    .delete_btn {
      background: $secondaryBtnColor;
      width: 106px;
      color: $inputTextColor;
    }
  }

  .trial_agencies {
    display: flex;
    justify-content: space-between;

    .react-calendar {
      width: 200px !important;
    }
  }
}

.invite_wrapper .react-responsive-modal-modal {
  border-radius: 16px;

}

.orgModalAgency {
  height: 310px !important;
  width: 500px !important;
  border-radius: 16px;
}