@import '../../index.scss';
.bulk_upload_container {
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  border-radius: 16px;
  .bulk_upload_wrapper {
    padding: 0;
    width: 100%;
    border-radius: 24px;
    .bulk_upload_header {
      padding: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $secondaryBtnColor;
      .bulk_upload_heading {
        font-family: 'Poppins';
        font-size: 24px;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: 0px;
        padding-bottom: 0px;
      }
    }
    .navbutton_wrapper {
      height: 35px;
      width: 35px;
    }
    .bulk_upload_resume_panel {
      display: flex;
    }
    .bulk_upload_resume {
      width: 30%;
      height: 570px;
      overflow-y: scroll;
      padding: 20px;
      display: flex;
      gap: 10px;
      flex-direction: column;
      border-right: 1px solid $secondaryBtnColor;
    }
    .upload_resume_panel {
      cursor: pointer;
      display: flex;
      align-items: center;
      height: 60px;
      justify-content: space-between;
    }
    .resume_file_panel {
      display: flex;
      width: 80%;
      gap: 10px;
      align-items: center;
      margin-left: 5px;
    }
    .select_line {
      width: 4px;
      height: 60px;
      border-radius: 8px;
    }
    .resume_format_line {
      width: 20%;
      display: flex;
      align-items: center;
      gap: 10px;
    }
    .resume_file_format {
      width: 80%;
    }
    .resume_file_name {
      font-family: Poppins;
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .resume_format_size {
      display: flex;
      font-family: Inter;
      font-size: 13px;
      font-weight: 400;
      line-height: 24px;
      color: $grayText;
      gap: 10px;
    }
    .resume_preview {
      width: 36%;
      padding: 20px;
      .react-pdf__Page__canvas {
        width: 88% !important;
        height: 480px !important;

      }
      .pdf_wrapper {
        height: 510px;
        border: $primaryBlackColor 1px solid;
        border-radius: 16px;
      }
    }
    .preview_title {
      color: $primaryGrayColor;
      font-family: Inter;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      margin-bottom: 15px;
    }

    .candidate_email_wrapper {
      width: 33%;
      padding: 20px;
    }
    .candiate_email {
      margin-bottom: 20px;
    }
    .candidate_title {
      color: $primaryGrayColor;
      font-family: Inter;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      margin-bottom: 15px;
    }
    .email_send_button {
      display: flex;
      justify-content: flex-end;
    }
    .send_bulk_upload_button {
      width: 150px;
    }
  
  }
}
.bulkOverlay + .react-responsive-modal-container {
  overflow: hidden !important;
}

.bulk_upload_wrapper .react-responsive-modal-modal {
  border-radius: 16px;
  width: auto !important;
}

.bulkUploadModal {
  min-width: 1200px !important;
  padding: 0 !important;
  height: 650px;
  border-radius: 16px;
  overflow: visible;
}
