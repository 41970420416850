@import '../../index.scss';

.email_resume_modal_wrapper {
  border-radius: 16px;
  width: 700px !important;
}
.email_resume_container {
}
.email_resume_header {
  display: flex;
  justify-content: space-between;
  .email_resume_heading {
    font-family: 'Poppins';
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0px;
    padding-bottom: 20px;
  }
  .email_resume_name_container {
    width: '100%';
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}
.email_resume_name_label {
  font-family: 'Poppins';
  font-size: 12px;
  color: $inputHeadingColor;
  padding: 10px 0;
}
.email_resume_text_edtior {
  padding: 10px 0;
}
.email_resume_button_wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
}
.email_resume_btn {
  margin-left: 20px;
  padding: 10px 30px;
  border-radius: 10px;
}
.email_resume_attachment_wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.email_resume_attachment {
  width: 200px;
  margin-right: 20px;
}
.email_recipients_wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  .email_recipient {
    font-family: 'Poppins';
    font-size: 10px;
    color: $inputHeadingColor;
    padding: 10px 0;
    &:hover {
      cursor: pointer;
    }
  }
}
