@import '../../index.scss';

.organization_container {
  .organization_wrapper {
    width: 45%;
    background-color: $buttonTextColor;
    padding: 20px;
    border-radius: 24px;
    box-shadow: #E3E6EC 0px 8px 24px;
    margin-top: 10px;

    .heading {
      width: 100%;
      font-family: 'Poppins';
      font-weight: 500;
      color: $inputTextColor;
      font-size: 20px;
      letter-spacing: -0.5px;
      text-align: left;
    }

    .input_heading {
      color: $inputHeadingColor;
      font-size: 12px;
      font-weight: 500;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      padding-top: 40px;
      padding-bottom: 10px;
      font-family: 'Inter';
    }

    .postjob_second_container {
      width: 46%;
      display: flex;
      flex-direction: column;
      gap: 20px;
      height: 100%;

      .company_details {
        textarea {
          height: 120px;
        }
      }

      textarea {
        background-color: $inputBackground;
        height: 380px;
        padding: 10px;
        border-radius: 8px;
        resize: none;
        width: 100%;
        border: 0px;
      }
    }

    .save_and_continue {
      width: 400px;
    }
  }
}

.avatar_heading {
  justify-content: center !important;
}

.name_input {
  width: 98% !important;
}