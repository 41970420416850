@import '../../index.scss';

.login_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .login_wrapper {
    width: 580px;
    height: 518px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .welcome {
      font-size: 24px;
      width: 580px;
      height: 32px;
      text-align: center;
      font-weight: 500;
      font-family: 'Poppins';
    }

    .login_text {
      width: 580px;
      height: 48px;
      font-size: 32px;
      text-align: center;
      font-weight: 500;
      font-family: 'Poppins';
    }

    .inputs {
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 400px;
      .input_heading {
        color: $inputHeadingColor;
        font-size: 12px;
        margin-bottom: 10px;
        font-family: 'Inter';
        font-weight: 500;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        padding-top: 20px;
      }

      .input_box {
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }
.forgot_link{
  cursor: pointer;
}
    .forgot_password {
      color: $primaryLinkColor;
      width: 100%;
      font-weight: 600;
      display: flex;
      justify-content: flex-end;
      padding-top: 15px;
      padding-bottom: 30px;
      font-size: 13px;
    }

    .login_button {
      width: 400px;
      border-radius: 16px;
      border: 0px;
      color: $buttonTextColor;
      font-size: 14px;
      font-weight: 700;
      cursor: pointer;

      .login_btn {
        background: $gradientButtonColor;
        font-family: 'Inter';
        font-weight: 700;
      }
    }

    .footer {
      font-size: 13px;
      line-height: 18px;
      text-align: center;
      font-weight: 600;
      padding-top: 20px;
      display: flex;

      .sign_up {
        color: $primaryLinkColor;
        margin-left: 15px;
        cursor: pointer;
      }
    }
  }
}
