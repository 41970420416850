@import '../../index.scss';

.invite_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .invite_wrapper {
    width: 400px;
    .invite_container_logo {
      text-align: center;
    }

    .invite_heading {
      text-align: center;
      font-size: 32px;
      line-height: 48px;
      font-weight: 500;
    }

    .sub_heading {
      width: 100%;
      height: 26px;
      font-family: 'Inter';
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      padding-bottom: 60px;
    }

    .inputs {
      padding-top: 30px;
      padding-bottom: 30px;
      width: 100%;
    }

    .input_heading {
      color: $inputHeadingColor;
      font-size: 12px;
      font-weight: 500;
      padding-bottom: 15px;
    }
  }
  .invite_button {
    background: $gradientButtonColor;
  }
  .back_to_login {
    width: 100%;
    display: flex;
    justify-content: center;
    color: $primaryLinkColor;
    padding-top: 20px;
    cursor: pointer;
  }
  .invite_button_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px
  }
}
