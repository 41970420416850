@import '../../index.scss';
.resumelist_container {
  display: flex;
  margin: 10px;
  // padding: 20px 0;
  border-bottom: 1px solid $secondaryLightColor;
  &:hover {
    cursor: pointer;
  }
  .resumelist_checkbox_image {
    width: 15%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    .resumelist_checkbox {
      width: 20%;
      padding-top: 10px;
      &:hover {
        cursor: pointer;
      }
    }
    .resumelist_profile_image {
      width: 80%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
    }
  }
  .resumelist_wrapper {
    width: 85%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    .resumelist_heading {
      font-family: 'Inter';
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0px;
    }
    .resumelist_value {
      font-family: 'Inter';
      font-size: 13px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0px;
      color: $inputPlaceholder;
    }
    .resumelist_info {
      display: flex;
      .resumelist_name_container {
        width: 20%;
      }
      .resumelist_gender_container {
        width: 20%;
      }
      .resumelist_location_container {
        width: 20%;
      }
      .resumelist_dropdown_container {
        width: 230px;
      }
      .resumelist_add_button_container {
        width: 30%;
        display: flex;
        justify-content: flex-end;
        padding-right: 20px;
        .add_button {
          width: 56px;
        }
      }
    }
  }
}
.resume_list_profile_image {
  height: 45px;
  width: 45px;
  border-radius: 100px;
}
.text_spacing {
  padding: 0 10px;
}
.resumelist_add_button_container {
  // width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
  gap: 30px;
  .add_button {
    height: auto;
    // padding: 8px;
  }
}
.resume_search_download_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1b1d21;
  padding: 10px 20px;
  border-radius: 16px;
  font-family: 'Inter';
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: $buttonTextColor !important;
  .download_icon_wrapper {
    padding-right: 5px;
  }
}
.download_text {
  font-size: 12px;
}
.download_icon_wrapper {
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  .resume_download_icon {
    width: 15px;
    height: 15px;
  }
}
.resumelist_footer_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
