@import '../../index.scss';

.client_org_wrapper {
  width: 100%;
  box-shadow: #e3e6ec 0px 8px 24px;
  border-radius: 24px;
  padding: 30px 0;
  margin-top: 30px;
  padding: 20px;

  .client_org_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .search_org {
      width: 320px;
      border-radius: 8px;
    }
  }

  .org_list_wrapper {
    margin-top: 10px;

    .org_list_component_action_btn {

      height: 40px;
      // padding: 15px;
      border-radius: 12px;
      margin-right: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: $secondaryBtnColor;
      color: $inputTextColor;

      .list_component_icon-wrapper {
        padding-right: 7px;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
}