@import '../../../index.scss';

.dropdown_wrapper {
  display: flex;
  align-items: center;
  background: $inputBackground;
  width: 100%;
  padding: 0px 20px;
  border-radius: 16px;
  border: 2px solid $buttonTextColor;
}
.dropdown_active_text {
  height: 56px;
  width: 98%;
  border-radius: 16px;
  color: black;
  align-items: center;
  justify-content: flex-start;
  display: flex;
  font-size: 14px;
  font-family: 'Inter';
  font-weight: 600;
}
.arrow {
  height: 8px;
  width: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.arrow_active {
  transform: rotate(180deg);
}
.dropdown_list {
  margin-top: 10px;
  padding: 10px 0px;
  width: 100%;
  border-radius: 24px;
  border: 1px solid #e4e4e4;
  max-height: 140px;
  overflow: auto;
}
.list_text {
  padding: 10px 20px;
  width: 100%;
  font-size: 14px;
  font-family: 'Inter';
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
  color: $inputTextColor;
}
.list_text:hover {
  cursor: pointer;
}
.dropdown_focus {
  border: 2px solid #5f75ee;
  color: $buttonTextColor;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0px 20px;
  border-radius: 16px;
}
// ::-webkit-scrollbar {
//   display: none;
// }
.css-1s2u09g-control {
  background-color: $inputBackground !important;
  border-color: $buttonTextColor !important;
  border-width: 2px !important;
  // height: 50px !important;
  border-radius: 8px !important;
  padding: 8px 10px !important;
}
.css-1pahdxg-control {
  // border-width: 0px !important;
  // box-shadow: none !important;
  background-color: $inputBackground !important;
  // padding: 5px;
  padding: 8px 10px;
  border-radius: 8px !important;
}

.css-1pahdxg-control:hover {
  // border-color: none !important;
}
.css-1okebmr-indicatorSeparator {
  display: none;
}
.css-1rhbuit-multiValue {
  border-radius: 12px !important;
  background: $gradientButtonColor;
  display: flex;
  justify-content: space-between;
  width: max-content;
  color: $buttonTextColor;
  height: 36px;
  padding: 0px 6px;
}
.css-12jo7m5 {
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: white!important;
}
.css-qc6sy-singleValue {
  color: $inputTextColor !important;
  font-family: 'Inter';
  font-weight: 600;
  font-weight: 600;
  font-size: 14px;
}
.css-26l3qy-menu {
  border-radius: 8px !important;
}
.css-tlfecz-indicatorContainer {
  padding: 8px 0px !important;
}
.css-1gtu0rj-indicatorContainer {
  padding: 8px 0px !important;
}
.dropdown_clear_text {
  width: '100%';
  font-family: 'Inter';
  font-size: 10px;
  padding-right: 10px;
  position: absolute;
  right: 0px;
  // z-index: 100;
  bottom: 55px;
  &:hover {
    cursor: pointer;
  }
}

.dropdown_clear_multi {
  width: '100%';
  font-family: 'Inter';
  font-size: 10px;
  padding-right: 10px;
  position: absolute;
  right: 0px;
  // z-index: 100;
  bottom: 65px;
  &:hover {
    cursor: pointer;
  }
}
.dropdown_control {
  background-color: $inputBackground !important;
  border-color: $buttonTextColor !important;
  border-width: 2px !important;
  // height: 50px !important;
  border-radius: 8px !important;
  padding: 8px 10px !important;
}
.dropdown_indicator_separator {
  // background-color: $inputBackground !important;
  // border-color: $buttonTextColor !important;
  // border-width: 2px !important;
  // // height: 50px !important;
  // border-radius: 8px !important;
  // padding: 8px 10px !important;
  display: none
}
.dropdown_multi_value{
  border-radius: 12px !important;
  background: $gradientButtonColor;
  display: flex;
  justify-content: space-between;
  width: max-content;
  color: $buttonTextColor;
  height: 36px;
  padding: 0px 6px;
}
.dropdown_multi_value_label {
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: white !important;
}
.dropdown_single_value{
  color: $inputTextColor !important;
  font-family: 'Inter';
  font-weight: 600;
  font-weight: 600;
  font-size: 14px;
}