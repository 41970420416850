@import '../../index.scss';

.upload_resume_container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .upload_resume_wrapper {
    padding: 20px;
    width: 100%;
    border-radius: 24px;
    .upload_resume_header {
      display: flex;
      justify-content: space-between;
      .upload_resume_heading {
        font-family: 'Poppins';
        font-size: 24px;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: 0px;
        padding-bottom: 20px;
      }
    }
  }
}
.upload_resume_wrapper_modal {
  border-radius: 16px;
  width: 700px !important;
}
.upload_resume_file_upload_image {
  width: 100%;
  height: 300px;
}
.upload_resume_input_section_container {
  width: 100%;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  .upload_resume_dropdown_section_container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    .upload_resume_dropdown_container {
      width: 48%;
      .upload_resume_input_heading {
        font-family: 'Inter';
        font-weight: 500;
        font-size: 12px;
        color: $inputHeadingColor;
        padding-bottom: 10px;
      }
    }
  }
  .upload_resume_input_section_content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .upload_resume_input {
      width: 30%;
      .upload_resume_input_heading {
        font-family: 'Inter';
        font-weight: 500;
        font-size: 12px;
        color: $inputHeadingColor;
        padding: 13px 0;
      }
    }
  }
}
