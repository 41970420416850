@import '../../index.scss';
.candidate_info_container {
  // width: 750px;
  height: 100%;
  padding: 0px 20px;
  border-radius: 24px;
  // box-shadow: #E3E6EC 0px 8px 24px;
  .candidate_info_header {
    width: 100%;
    font-family: 'Poppins';
    font-size: 14px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .candidate_info_wrapper {
    display: flex;
    padding-top: 20px;
    height: 95%;
    .candidate_info_profile {
      // width: 20%;
      display: flex;
      align-items: flex-start;
      padding-right: 10px;
      .candidate_info_profile_picture {
        width: 100%;
        .candidate_image {
          width: 60px;
          display: flex;
          align-items: flex-start;
          justify-content: center;
          height: 60px;
          border-radius: 100px;
          object-fit: cover;
        }
      }
    }
    .candidate_info_information {
      width: 85%;
      padding-left: 10px;
      display: flex;
      flex-flow: column wrap;
      flex-direction: column;
      overflow: auto;
      align-content: flex-start;
      max-height: 500px;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 20px;
      .candidate_position_container {
        padding-bottom: 1px;
        max-width: 200px;
        .candidate_info_headings {
          font-family: 'Inter';
          font-size: 14px;
          font-weight: 600;
          padding-bottom: 3px;
          text-transform: capitalize;
        }
        .candidate_info_value {
          font-family: 'Inter';
          font-size: 13px;
          font-weight: 400;
          color: $inputHeadingColor;
          // white-space: nowrap;
          // overflow: hidden;
          // text-overflow: ellipsis;
        }
      }
    }
  }
}
.candidate_download_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $primaryBtnColor;
  padding: 10px 20px;
  border-radius: 100px;
  .download_text {
    color: $buttonTextColor;
    font-family: 'Poppins';
    font-size: 14px;
    font-weight: 500;
    padding-left: 10px;
  }
}
// .candidate_info_information {
//   width: 80%;
//   display: flex;
//   flex-flow: column wrap;
//   flex-direction: column;
//   overflow: auto;
//   align-content: flex-start;
//   max-height: 100%;
//   align-items: flex-start;
//   justify-content: flex-start;

//   .candidate_info_headings {
//     font-family: 'Inter';
//     font-size: 14px;
//     font-weight: 600;
//     padding-bottom: 10px;
//   }
//   .candidate_info_value {
//     font-family: 'Inter';
//     font-size: 13px;
//     font-weight: 400;
//     color: $inputHeadingColor;
//   }
// }
// .container_padding{
//     padding-bottom: 20px;
// }
// .candidate_info_profile_picture{
//   object-fit: cover;
//   width: 100%;
// }

.candidate_info_icon {
  cursor: pointer;
}
.candidate_info_component_header {

  display: flex;
  justify-content: flex-end;
  align-items: center;
  // width: 100%;
}
.candidate_info_icon_wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
