@import '../../index.scss';
.email_list_container {
  margin: 20px;
  border-bottom: 1px solid $secondaryBtnColor;
  display: flex;
  gap: 20px;
  padding-bottom: 20px;
  .email_profile_container {
    width: 10%;
  }
  .email_list_wrapper {
    width: 90%;
    .email_list_header {
      display: flex;
      justify-content: space-between;
      padding-bottom: 10px;
      .email_user_name {
        font-family: 'Inter';
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0px;
        text-align: left;
      }
      .email_time_container {
        font-family: 'Inter';
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: left;
        color: $inputHeadingColor;
      }
    }
    .email_content_container {
      font-family: 'Inter';
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0px;
      text-align: left;
      color: $inputHeadingColor;
    }
  }
}