@import '../../index.scss';

.manage_users_container {
  padding: 20px;
  // height: 100%;
  box-shadow: #E3E6EC 0px 8px 24px;
  border-radius: 24px;
  margin-top: 10px;
  .header {
    display: flex;
    justify-content: space-between;

    .manage_users_heading {
      // width: 100%;
      font-family: 'Poppins';
      font-weight: 500;
      color: $inputTextColor;
      font-size: 20px;
      letter-spacing: -0.5px;
      text-align: left;
    }

    .download_and_upload {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      .upload {
        // width: 280px;
        // height: 56px;
        // background: $socialBtnColor;
        margin-left: 15px;
        // margin: 10px 20px 0px;
        font-size: 14px;
        font-weight: 700;
      }
      .download {
        background: $primaryBtnColor;
        border-radius: 10px;
        // padding: 6px 10px;
        font-size: 14px;
        font-weight: 700;
        font-family: 'Inter';
      }
    }
  }
  .inputs {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-top: 40px;
    .input_heading {
      color: $inputHeadingColor;
      font-size: 12px;
      font-weight: 500;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      //   padding-top: 40px;
      padding-bottom: 10px;
      font-family: 'Inter';
    }
    .firstname_wrapper {
      width: 20%;
    }
    .lastname_wrapper {
      width: 20%;
    }
    .email_wrapper {
      width: 20%;
    }
    .role_wrapper {
      width: 20%;
    }
    .send_invite_wrapper {
      // width: 15%;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      align-content: center;
      height: 100%;
      margin-top: 25px;
      .send_invite_button {
        background: $gradientButtonColor;
      }
    }
  }
  .search_wrapper {
    padding-top: 40px;
    padding-bottom: 10px;
    width: 84%;
  }
}
.download {
  // width: 200px;
  display: flex;
  color: white !important;
  padding: 8px 20px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  font-family: 'Inter';
  font-weight: 600;
  font-size: 14px;
  // line-height: 20px;

  .download_icon {
    margin-right: 10px;
  }
}
.delete_pop_container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  .delete_pop_button_wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    .delete_pop_btn {
      width: 120px;
    }
    .delete {
      background: $cancel;
    }
  }
}
.user_table_status {
  font-family: 'Inter';
  font-weight: 600;
  font-size: 13px;
  text-align: center;
  padding: 5px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}
.user_table_title {
  &:hover {
    cursor: pointer;
  }
}
.user_table_download_icon {
  height: 20px;
  width: 20px;
}
.download_and_upload {
  display: flex;
  justify-content: space-between;
  .download,
  .upload {
    // width: 280px;
    // height: 56px;
    // background: $socialBtnColor;
    margin-left: 15px;
    border-radius: 16px!important;
    padding: 13px 16px;
    // margin: 10px 20px 0px;
  }
}
.send_invite_button {
  min-width: 100px;
}

.manage_user_screen_table_wrapper{
  max-height: 500px;
  overflow-y: auto;
}