.loader_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}

.loader {
  width: 40px;
  height: 40px;
  object-fit: contain;
}
