@import '../../../index.scss';

.invite_agency_container {
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  border-radius: 16px;

  .prompt_modal_wrapper {
    padding: 16px;
    width: 100%;
    border-radius: 24px;

    .invite_header {
      display: flex;
      justify-content: space-between;

      .invite_heading {
        font-family: 'Poppins';
        font-size: 24px;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: 0px;
        padding-bottom: 20px;
      }
    }

    .text_area_size {
      height: 160px;
    }

    .prompt_text_area {
      height: 150px !important;
    }

    .invite_subheading {
      font-family: 'Inter';
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: 0px;
      text-align: left;
      padding-bottom: 10px;
      margin-bottom: 5px;

    }

    .invite_send_button {
      display: flex;
      justify-content: flex-end;
      gap: 10px;
      padding-top: 30px;
    }

    .send_invite_button {
      width: 106px;
    }

    .delete_btn {
      background: $secondaryBtnColor;
      width: 106px;
      color: $inputTextColor;
    }
  }
}

.invite_wrapper .react-responsive-modal-modal {
  border-radius: 16px;

}

.promptModalAgency {
  height: 340px !important;
  width: 600px !important;
  border-radius: 16px;
}