@import '../../index.scss';
.profile_container {
  // background-color: $background;
  // padding: 30px;
  // height: 100%;
  .profile_wrapper {
    width: 45%;
    background-color: $buttonTextColor;
    padding: 20px;
    border-radius: 24px;
    box-shadow: #E3E6EC 0px 8px 24px;
    margin-top: 10px;
    .heading {
      width: 100%;
      font-family: 'Poppins';
      font-weight: 500;
      color: $inputTextColor;
      font-size: 20px;
      letter-spacing: -0.5px;
      text-align: left;
    }
    .input_heading {
      color: $inputHeadingColor;
      font-size: 12px;
      font-weight: 500;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      padding-top: 40px;
      padding-bottom: 10px;
      font-family: 'Inter';
    }
    .photo_and_button {
      display: flex;
      justify-content: center;
      align-items: center;
      .upload_and_delete {
        display: flex;
        justify-content: space-around;
        padding-top: 20px;
        padding-left: 20px;
        .upload_button {
          width: 264px;
          height: 56px;
          background: $gradientButtonColor;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          margin-left: 20px;
        }
        .delete_avatar {
          width: 256px;
          height: 56px;
        }
        .delete_avatar_button:hover {
          color: $buttonTextColor;
        }
      }
    }
    .full_name {
      // height: 88px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      // margin-bottom: 20px;

      .first_name,
      .last_name {
        width: 48%;
        // width: 332px;
        // height: 56px;
      }
    }
    .buttons {
      padding-top: 30px;
      width: 100%;
      .linkedin_button,
      .facebook_button {
        display: flex;
        margin-bottom: 20px;
        .social_button {
          width: 400px;
        }
        .disconnect {
          padding-top: 20px;
          padding-left: 35px;
          color: $secondaryDarkText;
          font-size: 14px;
          font-weight: 600;
          font-family: 'Inter';
        }
      }
      .save_and_continue {
        width: 400px;
      }
    }
  }
}
.avatar_heading {
  justify-content: center !important;
}
.name_input {
  width: 98% !important;
}
