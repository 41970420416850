@import '../../index.scss';

.candidate_join_modal_wrapper {
  border-radius: 16px;
  width: 350px !important;
  height: 400px !important;
}
.candidate_join_modal_container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  height: 100%;
}
.candidate_join_modal_heading {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 20px;
}
.candidate_join_button_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 50px;
  width: 100%;
}
.candidate_join_button {
  width: 140px;
}
