@import '../../../index.scss';
.search_input_container {
  display: flex;
  flex-direction: row;
  background-color: $inputBackground;
  border-radius: 8px;
  align-items: center;
  justify-content: flex-start;
  padding: 3px 10px;
  width: 100%;
  .icon_wrapper {
    width: 25px;
  }
  .input {
    background-color: $inputBackground;
    border: 0px;
    border-radius: 8px;
    outline: 0px;
    padding: 10px;
    font-size: 14px;
    color: $inputPlaceholder;
    width: 90%;
    font-family: 'Inter';
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }
  .input_icon {
    width: 20px !important;
    height: 20px !important;
  }
}
.search_bar_focus {
  outline: 2px solid #1d7bdf;
}
.search_bar_set_bg {
  background-color: $buttonTextColor !important;
  color: $inputTextColor !important;
  font-weight: 600;
}
