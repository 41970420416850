@import '../../../index.scss';

.approval_team_container {
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 470px;
  border-radius: 16px;
}

.approval_reject_container {
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 450px;
  border-radius: 16px;
}

.approval_wrapper {
  padding: 16px;
  width: 100%;
  border-radius: 24px;

  .approval_header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .approval_heading {
      font-family: 'Poppins';
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
      letter-spacing: 0px;
    }
  }

  .approval_subheading {
    font-family: 'Inter';
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;
    padding: 10px 0;
  }

  .approval_send_button {
    display: flex;
    justify-content: flex-end;
    padding-top: 30px;
  }
}

.approval_wrapper .react-responsive-modal-modal {
  border-radius: 16px;
  width: auto !important;
}

.approvalModal {
  // height: 400px;
  border-radius: 16px;
  padding: 8px !important;
  overflow-y: visible !important;

}

.approval_email {
  display: flex;
  align-items: center;
}

.approval_reject_notes {
  margin: 20px 0 0 0;
}

.reject_notes {
  height: 150px !important;
}