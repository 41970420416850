@import '../../index.scss';
.setup_container {
  .setup_header {
    display: flex;
    justify-content: space-between;
    .setup_back_and_text {
      display: flex;
      gap: 10px;
      align-items: center;
      justify-content: center;
      .setup_back {
        &:hover {
          cursor: pointer;
        }
      }
      .setup_header_text {
        font-family: 'Poppins';
        font-size: 20px;
        font-weight: 600;
      }
    }
  }
  .setup_wrapper1 {
    display: flex;
    gap: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    .setup_wrapper1_main {
      width: 70%;
      box-shadow: #E3E6EC 0px 8px 24px;
      border-radius: 24px;
      padding: 20px;
      height: 100%;
      .setup_info_container {
        .setup_info_wrapper {
          display: flex;
          justify-content: space-between;
          padding-bottom: 20px;
          .setup_info_time_wrapper {
            display: flex;
            gap: 10px;
          }
        }
      }
    }
    .setup_wrapper1_sub {
      width: 30%;
      .setup_checkbox_wrapper {
        display: flex;
        padding-top: 15px;
        gap: 5px;
        align-items: center;
        &:hover {
          cursor: pointer;
        }
      }
      .setup_link_container {
        padding-top: 15px;
        padding-bottom: 15px;
      }
    }
  }
  .editor_container {
    display: flex;
    padding-top: 30px;
    gap: 20px;
    padding-bottom: 30px;
    .setup_editor_container {
      box-shadow: #E3E6EC 0px 8px 24px;
      border-radius: 24px;
      padding: 20px;
      width: 70%;
    }
    .setup_templates_attachment_container {
      .setup_attachment_container {
        padding-top: 20px;
        .setup_attachment_wrapper {
          display: flex;
          flex-direction: column;
          gap: 10px;
        }
      }
    }
  }
  .setup_subheading {
    font-family: 'Poppins';
    font-size: 14px;
    font-weight: 500;
    padding-bottom: 20px;
  }
  .setup_info_input_heading {
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 500;
    padding-bottom: 10px;
    color: $inputHeadingColor;
  }
  .setup_upload_button {
    // width: 50%;
  }
}

.setup_info_round_container {
  width: 25%;
}
.setup_info_date_container {
  width: 25%;
}
.setup_info_time_container {
  width: 45%;
}
.setup_templates_dropdown {
}
.setup_templates_attachment_container {
  width: 30%;
}

.interview_google_maps_picker {
  height: 300px !important;
  width: 100%;
}
#google-map-view-acd4ulb11 {
  height: 300px !important;
  width: 100%;
}
.interview_setup_address {
  margin: 20px 0;
}
.interview_setup_hiring_team {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
}
.interview_setup_hiring_team-list {
  width: 100%;
}
.setup_upload_button {
  width: 180px;
}
.interview_setup_back_icon {
  width: 15px;
  height: 15px;
}
.setup_calender_wrapper {
  border: 1px solid $primaryBorderColor;
  border-radius: 16px;
  .setup_calender_header {
    border-bottom: 1px solid $primaryBorderColor;
    padding: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .calendar_timeline {
      width: 65%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: 'Inter';
      font-size: 10px;
      font-weight: 500;
      color: $inputHeadingColor;
    }
  }
  .setup_calender_footer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    .interview_setup_hiring_team {
      display: flex;
      flex-direction: column;
      width: 40%;
      padding: 10px 10px 10px 10px;
      border-right: 1px solid $primaryBorderColor;
      // padding-right: 10px;
      align-items: flex-start;
    }
    .setup_calender_timechart {
      width: 65%;
      // padding: 20px;
      // padding-left: 0px;
      align-items: center;
      width: 65%;
      position: relative;
      height: 40px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .setup_calender_timechart_1 {
        border-radius: 8px;
        width: 140px;
        height: 35px;
        background: $socialBtnColor;
        position: absolute;
      }
    }
  }
}
.interview_setup_submit_btn {
  min-width: 130px;
}
.interview_setup_hiring_team_list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 35%;
  padding-left: 10px;
}
.calender_not_integrated {
  width: 98%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $secondaryBtnColor;
  border-radius: 8px;
  color: $grayText;
  font-family: 'Inter';
  font-weight: 500;
  font-size: 12px;
}
