@import '../../index.scss';
.businessunit_container {
  padding: 20px;
  // height: 100%;
  box-shadow: #e3e6ec 0px 8px 24px;
  border-radius: 24px;
  margin-top: 10px;
  .businessunit_heading {
    margin-bottom: 40px;
    width: 100%;
    color: $inputTextColor !important;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
  }
  .businessunit_wrapper {
    .businessunit_input_heading {
      color: $inputHeadingColor;
      font-size: 12px;
      font-weight: 500;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      padding-top: 40px;
      padding-bottom: 10px;
      font-family: 'Inter';
    }
    .businessunit_input_container {
      display: flex;
      flex-direction: column;
      .businessunit_input_and_button {
        display: flex;
        // justify-content: space-between;
        // align-items: flex-start;
        .businessunit_input_width {
          width: 70%;
          padding-top: 5px;
        }
        .add_button {
          width: 30%;
          margin-left: 20px;
        }
      }
    }
  }
}
.businessunit_table_container {
  padding-top: 20px;
  height: 500px;
  overflow: auto;
}
