@import '../../index.scss';
.recquisition_container {
  padding: 0px 20px;
  .recquisition_header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    .recquisition_heading_text {
      font-family: 'Poppins';
      font-size: 20px;
      font-weight: 600;
      line-height: 72px;
      letter-spacing: -1px;
      text-align: left;
    }
    .recquisition_button_container {
      padding-top: 20px;
      .recquisition_add_button {
        width: 220px;
      }
    }
  }
  .recquisition_wrapper {
    background: $gradientButtonColor;
    display: flex;
    height: 100px;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
    justify-content: space-around;
    .recquisition_wrapper_element {
      display: flex;
      flex-direction: column;
      gap: 5px;
      height: 100%;
      width: 100%;
      align-items: center;
      justify-content: center;
      border-right: 2px solid white;
      .recquisition_wrapper_headings {
        font-family: 'Inter';
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0px;
        color: $buttonTextColor;
      }
      .recquisition_wrapper_values {
        font-family: 'Poppins';
        font-size: 32px;
        font-weight: 500;
        color: $buttonTextColor;
      }
    }
  }
}
.requisition_board_table {
  width: 100%;
  box-shadow: #E3E6EC 0px 8px 24px;
  border-radius: 24px;
  margin-top: 20px;
  padding: 10px 20px;
  .recquisition_heading_text {
    font-family: 'Poppins';
    font-size: 20px;
    font-weight: 600;
  }
  .requisition_table_heading {
    margin: 15px 0;
  }
  .requistion_table_container {
    .requisition_board_table_content {
    }
  }
}
.requisition_dropdown{
  background-color: $checkboxActive !important;
  padding: 0 !important;
  border-radius: 8px !important;
  outline: none;
  border-color: transparent!important;
}
.requisition_dropdown {
  // color: $buttonTextColor !important;
}
.requisition_dropdown  {
  padding: 0 !important;
  min-height: 0px!important;
  background-color: $checkboxActive !important;
}
.requisition_dropdown {
  // color: $buttonTextColor !important;
}
.requisition_dropdown  {
  // color: $buttonTextColor !important;
}
.requisition_dropdown .single_value {
  color: $buttonTextColor !important;
  font-family: 'Poppins';
  font-weight: 500;
  text-align: center;
}
.requisition_dropdown .css-1s2u09g-control{
  min-height: 0;
}
.req_table_share_button {
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
// .req_share_wrapper {
//   width: 80%;
// }
.req_table_job_id {
  cursor: pointer;
}
.req_board_clipboard {
  height: 20px;
  width: 20px;
  margin-bottom: 5px;
}
.no_border_right {
  border-right: 0px !important;
}
.req_orgs_dropdown_container{
  width: 250px;
}
.req_menu_list{
  // color: $buttonTextColor !important
}