@import '../../index.scss';
.approve_offer_container {
  .approve_offer_header {
    display: flex;
    padding-bottom: 30px;
    .approve_offer_header_wrapper {
      display: flex;
      width: 70%;
      height: 100%;
      align-items: center;
      .approve_offer_header_text {
        font-family: 'Poppins';
        font-size: 30px;
        font-weight: 600;
        padding-left: 20px;
      }
    }
    .approve_offer_header_buttons {
      display: flex;
      gap: 10px;
      padding-top: 15px;
      .header_button {
        width: 126px;
      }
      .gray_button {
        background: $secondaryBtnColor;
        color: $primaryDarkColor;
      }
    }
  }

  .approve_offer_container1 {
    display: flex;
    justify-content: space-between;
    .approve_offer_details_container {
      width: 55%;
      border-radius: 24px;
      box-shadow: #E3E6EC 0px 8px 24px;
      padding: 20px;
      .approve_offer_details_wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
        .approve_offer_details_content {
          min-width: 170px;
          max-width: max-content;
          .approve_offer_details_heading {
            font-family: 'Poppins';
            font-size: 14px;
            font-weight: 500;
          }
          .approve_offer_details_value {
            font-family: 'Inter';
            font-size: 14px;
            font-weight: 400;
          }
        }
        .approve_offer_content_container {
          width: 100%;
          font-family: 'Inter';
          font-size: 14px;
          font-weight: 400;
          line-height: 24px;
        }
      }
    }
    .approve_offer_candidateinfo_container {
      width: 40%;
    }
  }
  .approve_offer_subheading {
    font-family: 'Poppins';
    font-size: 14px;
    font-weight: 500;
    padding-bottom: 20px;
    width: 100%;
  }
  .candidate_info_class{
      height: 400px;
  }
}
