@import '../../index.scss';

.react-kanban-column {
  padding: 0px;
  border-radius: 16px;
  background-color: $buttonTextColor !important;
  margin: 5px;
  border: 1px solid $secondaryBtnColor;
  // height: 100px !important;
  // overflow-y: scroll;
  width: 200px;
  // width: 250px;
}
.react-kanban-column-header {
  padding-bottom: 10px;
  font-weight: bold;
  border-bottom: 1px solid $inputHeadingColor;
}
.kanban_board_column_card_wrapper{
  height: 430px;
 overflow-y: scroll;
}
.kanban_board_column_wrapper {
  // max-height: 800px;
  // overflow-y: auto;
  width: 100%;
  height: 480px;
}
.kanban_board_column_card_wrapper{
   height: 430px;
  overflow-y: scroll;
}
.kanban_board_column_header {
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $inputHeadingColor;
  padding: 10px;
}
.kanban_column_data_count {
  background-color: #E8F2FC;
  padding: 10px;
  border-radius: 12px;
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 10px;
  color: $activeTabColor;
  min-width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.react-kanban-card {
  display: none;
}
.kanban_board_load_more_btn_wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 20px 0;
  cursor: pointer;

  .kanban_board_load_more_text {
    font-family: 'Inter';
    font-weight: 600;
    line-height: 20px;
    font-size: 12px;
    color: $primaryDarkColor;
    padding-bottom: 1px;
  }

  .load_more_line {
    height: 1px;
    width: 60px;
    text-align: center;
    background: $primaryDarkColor;
  }
}

.kanban_board_load_more_btn {
  min-width: 150px;
}

.kanban_column_heading {
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 16px;
}
.react-kanban-board {
  padding: 0;
}
.kanban_filter_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.kanban_column_filter_icon {
  position: relative;
  &:hover {
    cursor: pointer;
  }
}
.filter_content {
  position: absolute;
  right: 0px;
  padding: 10px;
  background-color: $buttonTextColor;
  border-radius: 14px;
  box-shadow: #e3e6ec 0px 8px 24px;
}
.kanban_column_filter {
  font-size: 10px;
  font-family: 'Inter';
  padding: 5px;
  border-radius: 8px;
  &:hover{
    cursor: pointer;
    background-color: $inputBackground;
  }
}
.kanban_content_wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 10px;
}
[data-rbd-droppable-context-id="1"]{
  display: flex;
}