@import '../../index.scss';
.home_container {
  padding: 20px 0;
  .home_heading_text {
    font-family: 'Poppins';
    font-size: 25px;
    font-weight: 600;
    line-height: 72px;
    text-align: left;
    padding-bottom: 20px;
  }
  .home_wrapper {
    display: flex;
    .home_main_container {
      width: 70%;
      .home_approval_container {
        padding: 10px;
        box-shadow: #e3e6ec 0px 8px 24px;
        border-radius: 24px;
        margin-bottom: 30px;
        .home_approval_header {
          font-family: 'Poppins';
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0px;
          text-align: left;
          margin: 8px 10px;
        }
        .home_approval_wrapper {
          max-height: 400px;
          overflow-y: auto;
          padding-bottom: 10px;
          margin-bottom: 10px;
        }
        .approval_btn{
          margin-right: 50px;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          box-shadow: #e3e6ec 0px 8px 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
      
      }
      .home_interview_container {
        padding: 10px;
        box-shadow: #e3e6ec 0px 8px 24px;
        border-radius: 24px;
        margin-bottom: 30px;
        .home_interview_header {
          display: flex;
          justify-content: space-between;
          margin: 10px;
          .home_interview_heading {
            font-family: 'Poppins';
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0px;
            text-align: left;
          }
        }
      }
      .home_feedback_container {
        padding: 10px;
        box-shadow: #e3e6ec 0px 8px 24px;
        border-radius: 24px;
        margin-top: 30px;
        margin-bottom: 30px;
        // max-height: 400px;
        // overflow-y: auto;
        .home_feedback_header {
          font-family: 'Poppins';
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0px;
          text-align: left;
          margin: 8px 10px;
        }
      }
    }
    .home_sub_container {
      width: 30%;
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 30px;
      padding: 0 30px;
      .home_sub_container_one {
        height: 85px;
        display: flex;
        padding: 10px 20px;
        box-shadow: #e3e6ec 0px 8px 24px;
        border-radius: 24px;
        width: 100%;
        align-items: center;
        justify-content: center;
        justify-content: space-between;
        .home_requisition_text {
          font-family: 'Poppins';
          font-size: 16px;
          font-weight: 500;
        }
        .home_reruisition_value {
          font-family: 'Poppins';
          font-size: 16px;
          font-weight: 500;
        }
      }
      .home_sub_container_two,
      .home_sub_container_three {
        width: 100%;
      }
    }
  }
  .home_offers_container {
    margin-top: 20px;
    padding: 10px;
    box-shadow: #e3e6ec 0px 8px 24px;
    border-radius: 24px;
    .home_offers_header {
      font-family: 'Poppins';
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0px;
      text-align: left;
      margin: 10px;
    }
  }
}
.home_interview_edit {
  // height: 30px;
  // width: 30px;
  &:hover {
    cursor: pointer;
  }
}
.home_interview_dropdown {
  min-width: 200px;
}
.add_table_btn {
  cursor: pointer;
}
.home_feedback_wrapper {
  max-height: 400px;
  overflow-y: auto;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

