@import '../../../index.scss';

.tag_container {
  border-radius: 12px;
  background: $gradientButtonColor;
  display: flex;
  padding: 10px;
  justify-content: space-between;
  min-width: 200px;
  height: 36px;
  margin: 10px 10px 10px 0;
  align-items: center;
  max-width: 200px;
  .tag_user_details {
    display: flex;
    justify-content: center;
    align-items: center;
    .tag_profile {
      height: 20px;
      width: 20px;
      border-radius: 100%;
      margin-right: 10px;
    }
    .tag_text {
      font-family: 'Inter';
      font-size: 12px;
      font-weight: 600;
      line-height: 20px;
      display: flex;
      // width: 75px;
      height: 100%;
      // justify-content: center;
      align-items: center;
      color: $buttonTextColor;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 120px;
      text-transform: capitalize;
    }
  }
  .tag_icon {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      cursor: pointer;
    }
  }
}
