@import '../../../index.scss';

.radio_container {
  margin: 20px 0;
  border-radius: 100px;
  display: flex;
  align-items: center;
  gap: 20px;
  border: none !important;
  &:hover {
    cursor: pointer;
  }
}
.radio_icon_wrapper {
  display: flex;
  align-items: center;
  gap: 7px;
  &:hover {
    cursor: pointer;
  }
}
.input_field_error {
  margin-top: 10px;
  font-family: 'Inter';
  font-weight: 400;
  font-size: 12px;
  color: $cancel;
}

.radio_label {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}