@import '../../../index.scss';

.table_text_editor {
  margin: 10px 0;

  .jodit-container:not(.jodit_inline) {
    border-radius: 8px !important;
    border: none !important;
    background-color: $inputBackground !important;
  }

  .jodit-toolbar__box:not(:empty):not(:empty) {
    background: $inputBackground !important;
    border: none;
  }

  .jodit-container:not(.jodit_inline) .jodit-workplace {
    background: $inputBackground !important;
    height: 200px !important;
    overflow-y: scroll !important;
  }

  .jodit-ui-group_line_true {
    margin-top: 4px !important;
    justify-content: center !important;
  }

  .jodit-toolbar-editor-collection_mode_horizontal:after {
    height: 0px !important;
    background: $inputBackground !important;
  }

  .jodit-workplace p {
    font-size: 12px;
  }
}