@import '../../index.scss';
%title {
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: $inputTextColor;
}
%para {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: $primaryBtnColor;
  margin: 10px 0 10px 0;
}
.requisition-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  .navbar {
    height: auto;
    ul {
      li {
        width: 100%;
      }
    }
  }
  .requisition-content-block {
    width: 100%;
    height: 100%;
    .requisition_header_container {
      .requisition_header_wrapper {
        display: flex;
        gap: 10px;
        height: 100%;
        align-items: center;
        justify-content: flex-start;
        // width: 25%;
        .requisition_header_icon {
          .back_icon {
            width: 15px;
            height: 15px;
          }
          &:hover {
            cursor: pointer;
          }
        }
        .requisition_header_text {
          font-family: 'Poppins';
          font-size: 20px;
          font-weight: 600;
          line-height: 72px;
          letter-spacing: -1px;
          text-align: left;
        }
      }
      .requisition_header_buttons {
        display: flex;
        gap: 10px;
        justify-content: flex-end;
        align-items: center;
        // width: 75%;
        .requisition_button_container {
          display: flex;
          gap: 10px;
          margin-top: 30px;
          align-items: center;
        }
        .requisition_header_dropdown {
          // width: 340px;
          .requisition_dropdown_heading {
            font-family: 'Inter';
            font-size: 12px;
            font-weight: 500;
            line-height: 16px;
            letter-spacing: 0px;
            text-align: left;
            color: $inputHeadingColor;
            padding-bottom: 10px;
          }
        }
        .header_button {
          // width: 180px;
        }
      }
    }
    .search-block {
      width: 100%;
      height: 315px;
      // background-color: cornsilk;
    }
    .content-block {
      width: 100%;
      height: 100%;
      margin-top: 20px;
      //   padding: 50px;
      .card {
        width: 100%;
        height: 100%;
        // padding: 20px;
        border-radius: 24px;
        // background-color: #fff;
        // box-shadow: -1px 28px 43px 1px rgba(227, 230, 236, 0.75);
        // -webkit-box-shadow: -1px 28px 43px 1px rgba(227, 230, 236, 0.75);
        // -moz-box-shadow: -1px 28px 43px 1px rgba(227, 230, 236, 0.75);
        .intro-id {
          display: flex;
          justify-content: space-between;
          // align-items: center;
          .title {
            font-family: 'Poppins';
            font-weight: 500;
            font-size: 32px;
            line-height: 48px;
            letter-spacing: -0.5px;
            color: $inputTextColor;
          }
          .edit-requisition {
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            .edit_req_title {
              font-family: 'Poppins';
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              color: $primaryBtnColor;
              padding-left: 5px;
            }
          }
        }
        .canditate_block {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          gap: 20px;
          .content_block {
            width: 65%;
            padding: 10px 20px;
            box-shadow: #e3e6ec 0px 8px 24px;
            border-radius: 24px;
            .data_wrapper {
                display: flex;
            
                .close_data,
                .open_data {
                  width: 25%;
                margin: 40px 0 0 0;
                .title {
                  @extend %title;
                }
                .para {
                  @extend %para;
                }
              }
            }
            .job_description_row {
              display: flex;
              margin: 20px 0 0 0;
              max-height: 350px;
              overflow: auto;
              .job_description_column {
                width: 25%;
                &.flexTwo {
                  flex-grow: 2;
                }
                &.flexFour {
                  width: 100%;
                }
                .title {
                  @extend %title;
                }
                .para {
                  @extend %para;
                }
                .buttonText {
                  font-family: 'Inter', sans-serif;
                  font-style: normal;
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 20px;
                  color: $buttonTextColor;
                  // margin: 10px 0 10px 0;
                  background-color: $activeTabColor;
                  border-radius: 12px;
                  padding: 10px 15px;
                  margin: 10px 10px 10px 0;
                  border: 1px solid $activeTabColor;
                  cursor: pointer;
                }
                .button_two_text {
                  &.hideCta {
                    display: none;
                  }
                  padding: 10px 15px;
                  display: inline-block;
                  margin: 10px 15px 10px 0;
                  background-image: $gradientButtonColor;
                  border-radius: 12px;
                  border: 1px solid transparent;
                  cursor: pointer;
                  .cta_block {
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    .cta_text {
                      font-family: 'Inter', sans-serif;
                      font-style: normal;
                      font-weight: 600;
                      font-size: 14px;
                      line-height: 20px;
                      color: $buttonTextColor;
                      margin: 0 0 0 10px;
                    }
                  }
                }
                .view_all {
                  display: inline-block;
                  .view_all_cta {
                    background-color: transparent;
                    border: 1px solid transparent;
                    outline: none;
                    cursor: pointer;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .view_text {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 500;
                      font-size: 14px;
                      line-height: 24px;
                      color: $primaryLinkColor;
                    }
                  }
                }
                .job_description_skills_wrapper,
                .job_description_behaviours_wrapper,
                .job_description_skills_wrapper {
                  display: flex;
                  flex-wrap: wrap;
                  .tag_color {
                    background: $activeTabColor;
                  }
                }
              }
            }
          }
          .image_block {
            width: 35%;
            // padding: 40px 0 0 20px;
            height: 520px;
            padding: 10px 20px;
            box-shadow: #e3e6ec 0px 8px 24px;
            border-radius: 24px;
          }
        }
      }
      .attachment_container {
        .attachment_files {
          display: flex;
        }
        .attachment_block {
          margin: 0 0 25px 0;
          display: flex;
          justify-content: space-between;
          .title {
            font-family: 'Poppins';
            font-weight: 500;
            font-size: 32px;
            line-height: 48px;
            color: $inputTextColor;
          }
          // .upload_cta {
          //   background-color: $primaryBtnColor;
          //   vertical-align: middle;
          //   width: auto;
          //   height: auto;
          //   border-radius: 16px;
          //   vertical-align: middle;
          //   display: flex;
          //   justify-content: space-evenly;
          //   align-items: center;
          //   padding: 0 25px;
          //   margin: 0 15px 0 0;
          //   p {
          //     font-family: 'Inter', sans-serif;
          //     font-style: normal;
          //     font-weight: 700;
          //     font-size: 14px;
          //     line-height: 24px;
          //     color: $buttonTextColor;
          //     padding: 0px 10px;
          //   }
          // }
          .cta_block {
            display: flex;
            justify-content: flex-start;
            gap: 20px;
            align-items: center;
          }
        }
      }
      .listed_container {
        // margin: 20px 0px;
        display: inline-block;
        white-space: nowrap;
        gap: 25px;
        overflow-x: auto;
        width: 100%;
        flex-grow: 1;
        .listed_block {
          border: 1px solid $primaryBorderColor;
          border-radius: 24px;
          width: 300px;
          height: 860px;
          display: inline-block;
          margin-right: 30px;
          .listed_title_block {
            border-bottom: 1px solid $inputPlaceholder;
            padding: 16px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .title {
              @extend %title;
              font-weight: 500;
            }
            .number {
              font-family: 'Inter', sans-serif;
              font-weight: 600;
              font-size: 14px;
              line-height: 20px;
              color: $secondaryBorderColor;
              background-color: $primaryNumberBg;
              padding: 7px 15px;
              border: 1px solid $secondaryBorderColor;
              border-radius: 20px;
            }
          }
          .listed_cards {
            overflow-y: auto;
            height: 790px;
            &::-webkit-scrollbar {
              display: none;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1600px) {
  .requisition-container {
    .requisition-content-block {
    }
  }
}

.requisition_header_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.job_req_header_button {
  padding: 0px 20px;
  width: 167px;
  height: 50px;
}
.job_description_row {
  display: flex;
  margin: 20px 0 0 0;
  max-height: 350px;
  overflow: auto;
  .job_description_column {
    width: 25%;
    &.flexTwo {
      flex-grow: 2;
    }
    &.flexFour {
      width: 100%;
    }
    .title {
      @extend %title;
    }
    .para {
      @extend %para;
    }
    .buttonText {
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: $buttonTextColor;
      // margin: 10px 0 10px 0;
      background-color: $activeTabColor;
      border-radius: 12px;
      padding: 10px 15px;
      margin: 10px 10px 10px 0;
      border: 1px solid $activeTabColor;
      cursor: pointer;
    }
    .button_two_text {
      &.hideCta {
        display: none;
      }
      padding: 10px 15px;
      display: inline-block;
      margin: 10px 15px 10px 0;
      background-image: $gradientButtonColor;
      border-radius: 12px;
      border: 1px solid transparent;
      cursor: pointer;
      .cta_block {
        display: flex;
        justify-content: space-around;
        align-items: center;
        .cta_text {
          font-family: 'Inter', sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          color: $buttonTextColor;
          margin: 0 0 0 10px;
        }
      }
    }
    .view_all {
      display: inline-block;
      .view_all_cta {
        background-color: transparent;
        border: 1px solid transparent;
        outline: none;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .view_text {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          color: $primaryLinkColor;
        }
      }
    }
    .job_description_skills_wrapper,
    .job_description_behaviours_wrapper,
    .job_description_skills_wrapper {
      display: flex;
      flex-wrap: wrap;
      .tag_color {
        background: $activeTabColor;
      }
    }
  }
}
.job_description_block {
  width: 65%;
  padding: 10px 20px;
  box-shadow: #e3e6ec 0px 8px 24px;
  border-radius: 24px;
  margin-top: 20px;
}
.job_description_skills_container{
  padding: 10px 20px;
  box-shadow: #e3e6ec 0px 8px 24px;
  border-radius: 24px;
  margin-top: 20px;
}
.skills_block {

  
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
}
.skills_hiring_interviewer_wrapper {
  width: 100%;
}
.behaviours_recruter_wrapper {
  width: 50%;
}
.skills_behaviour_wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.behaviour_container {
  width: 50%;
}
.skills_container {
  width: 50%;
}
.req_kanban_board_wrapper {
  padding: 20px;
  box-shadow: #e3e6ec 0px 8px 24px;
  border-radius: 24px;
  margin-top: 20px;
}
.approval_user_wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin: 16px 0px;

}

.approval_user_content {
  width: 73%;
  //styleName: headline/h6 - 18;
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #11142d;

}

.approval_user_btn {
  width: 27%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 10px;
}

.approval_view_title {
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 10px;
}

.approval_team_title {
  //styleName: body/body 1 - 14;
  font-family: 'Poppins';
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;

}

.approval_view_list {
  margin: 10px 0 20px 0px;
  max-height: 283px;
  overflow-y: scroll;
}