@import '../../index.scss';

.reset_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .reset_wrapper {
    width: 400px;
    .reset_container_logo {
      text-align: center;
    }
    .reset_heading {
      text-align: center;
      font-size: 32px;
      line-height: 48px;
      font-weight: 500;
    }

    .inputs {
      padding-top: 30px;
      padding-bottom: 30px;
      width: 100%;
    }

    .input_heading {
      color: $inputHeadingColor;
      font-size: 12px;
      font-weight: 500;
      padding-bottom: 15px;
      padding-top: 20px;
    }
    .button {
      background: $gradientButtonColor;
    }
  }
}
