@import '../../index.scss';
.application_container {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  .application_wrapper {
    width: 90%;

    .application_header {
      display: flex;
      padding-bottom: 30px;
      align-items: center;
      justify-content: space-between;
      .application_header_text {
        font-family: Poppins;
        font-size: 48px;
        font-weight: 600;
        line-height: 72px;
        letter-spacing: -1px;
        text-align: left;
        width: 70%;
      }
      .application_header_buttons {
        display: flex;
        gap: 10px;
        padding-top: 20px;
        .header_button {
          width: 126px;
        }
        .gray_button {
          background: $secondaryBtnColor;
          color: $primaryDarkColor;
        }
      }
    }
    .application_sub_header {
      display: flex;
      gap: 20px;
      padding-bottom: 20px;
      .application_sub_header_professional,
      .application_sub_header_personal {
        font-family: 'Poppins';
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: left;
      }
    }
    .application_wrapper {
      display: flex;
      .application_add_resume_container {
        width: 40%;
        margin-right: 30px;
        display: flex;
        flex-direction: column;
        gap: 30px;
        .application_sub_heading {
          font-family: 'Poppins';
          font-size: 14px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0px;
          text-align: left;
          height: '100%';
        }
      }
      .application_dropdown_container {
        display: flex;
        flex-direction: column;
        gap: 15px;
      }
      .application_resume_preview_container {
        width: 60%;
        margin-left: 30px;
        margin-bottom: 20px;
      }
    }
    .application_footer {
      display: flex;
      justify-content: flex-end;
      margin-top: 30px;
      gap: 20px;
      .application_footer_buttons {
        width: 125px;
      }
    }
    .application_input_heading {
      font-family: 'Inter';
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0px;
      text-align: left;
      color: $inputHeadingColor;
      padding-bottom: 10px;
    }
    .application_page_2 {
      .application_form_container {
        .application_name_container {
          display: flex;
          gap: 20px;
          padding-bottom: 30px;
        }
        .application_address_container {
          // padding-bottom: 30px;
          width: 19%;
          // padding-right: 15px;
        }
        .application_city_container {
          // padding-bottom: 25px;
          width: 19%;
        }
        .application_country_container {
          // padding-bottom: 25px;
          width: 19%;
        }
        .application_pincode_container {
          // padding-bottom: 25px;
          width: 24%;
        }
        .application_experience_container {
          padding-bottom: 30px;
          display: flex;
          gap: 20px;
        }
        .application_relocate_container {
          display: flex;
          gap: 20px;
          padding-bottom: 30px;
        }
        .application_salary_container {
          display: flex;
          padding-bottom: 30px;
          gap: 20px;
        }
      }
      .application_cretificate_container {
        .application_sub_heading {
          font-family: 'Poppins';
          font-size: 14px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0px;
          text-align: left;
        }
        .application_cretificate_wrapper {
          display: flex;
          gap: 20px;
          padding-top: 20px;
          // height: 140px;
          .application_certificate_one {
            width: 20%;
            height: 100%;
          }
          .application_certificate_add_tab {

            .green_add_button {
              border-radius: 16px;
              padding: 20px 20px;
              height: 60px;
              width: 180px;
              margin-bottom: 15px;
            }
          }
          .application_footer_page2 {
            width: 40%;
            height: 100%;
            display: flex;
            gap: 20px;
            align-items: flex-end;
            justify-content: flex-end;
            .application_footer_buttons {
              width: 125px;
            }
          }
        }
      }
    }
  }

  .width33 {
    width: 33%;
  }
  .width19 {
    width: 19%;
  }
  .width25 {
    width: 25%;
  }
}
.application_address {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 30px;
}
.application_skills_container {
  width: 70%;
  padding-bottom: 30px;
}
.application_profile_upload {
  padding: 20px;
  background-color: $inputBackground;
  border-radius: 100px;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.application_profile_picture {
  border-radius: 100px;
  width: 100px;
  height: 100px;
}
.application_profile_container {
  margin-bottom: 20px;
}
.header_button {
  width: 126px;
}
.add_employer {
  // height: 100%;
  border-radius: 16px;
  padding: 20px 20px;
  width: 20%;
  height: 60px;
  margin-bottom: 15px;
}
.application_employer_container {
  display: flex;
  flex-direction: column;
}
.application_employer {
  width: 100%;
  padding: 20px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}
.application_sub_heading {
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  padding-bottom: 10px;
}
.application_employer_input {
  width: 25%;
}
.application_employer_wrapper {
  width: 100%;
  // display: flex;
  // justify-content: flex-start;
  // align-items: center;
}
.employer_start_date_wrapper {
  width: 50%;
}
.application_date_container {
  width: 25%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}
.employer_navbutton_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
}
@media only screen and (max-width: 768px) {
  .application_sub_header {
    display: none !important;
  }
  .application_mobile_container {
    .upload_image {
      width: 100% !important;
    }
    .application_add_resume_container {
      width: 100% !important;
      margin: 0px !important;
    }
    .application_resume_preview_container {
      width: 100% !important;
      height: 100%;
      // margin: 0px !important;
    }
    .application_wrapper {
      width: 100% !important;
    }
    .application_page_2 {
      margin-top: 20px !important;
      .application_form_container {
        .application_profile_container {
          width: 100% !important;
          display: flex !important;
          justify-content: center !important;
        }
        .application_name_container {
          flex-direction: column !important;
          .application_firstname_container,
          .application_lastname_container,
          .application_gender_container {
            width: 100% !important;
          }
        }
        .application_address {
          flex-direction: column !important;
          gap: 20px !important;
          .application_address_container,
          .application_city_container,
          .application_country_container {
            width: 100% !important;
          }
        }
        .application_experience_container {
          flex-direction: column !important;
          .application_pincode_container,
          .application_totalexp_container,
          .application_relevantexp_container,
          .application_notice_container,
          .application_education_container {
            width: 100% !important;
          }
        }
        .application_relocate_container {
          flex-direction: column !important;
          .application_preference_container,
          .application_willingness_container,
          .application_reason_container {
            width: 100% !important;
          }
        }
        .application_salary_container {
          flex-direction: column !important;
          .width33 {
            width: 100% !important;
          }
        }
        .application_skills_container {
          width: 100% !important;
        }
      }
    }
    .application_employer {
      flex-direction: column !important;
    }
    .application_date_container,
    .application_employer_input {
      width: 100% !important;
    }
    .add_employer {
      width: 100% !important;
      height: 50px !important;
    }
    .application_certificate_add_tab {
      width: 100% !important;
    }
    .application_employee_trash_button {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }
    .employer_navbutton_wrapper {
      padding-top: 0px !important;
      padding-bottom: 10px;
    }

    .green_add_button {
      height: 50px !important;
      
    }
  }
  .application_header_text {
    width: 100% !important;
    font-size: 24px !important;
    text-align: center !important;
  }
  .mobile_footer_button {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .application_cretificate_wrapper {
    flex-direction: column !important;
    height: fit-content !important;
    padding-bottom: 30px;
  }
  .application_certificate_one {
    width: 100% !important;
  }
}
.application_sub_heading{
  height:100%
}