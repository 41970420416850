@import '../../../index.scss';

.funnel_container {
  width: 100%;
  .title {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #11142d;
  }
  .funnel_block {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    .funnel_image_block {
      width: 250px;
      img {
        margin-top: 25px;
      }
    }
    .text_block {
      width: 40%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-top: 25px;
      padding-left: 8px;

      .text_box {
        // margin: 0 0 15px 0;
        display: flex;
        flex-direction: column;
        // justify-content: space-between;
        width: 133px;
        height: 80px;
        position: relative;
        border-top: 1px solid $secondaryLightColor;
        .title {
          font-family: 'Inter', sans-serif;
          font-weight: 600;
          font-size: 10px;
          line-height: 18px;
          color: #808191;
          padding-top: 8px;
        }
        .number {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 12px;
          // line-height: 24px;
          color: #11142d;
          padding: 5px 0 0 0;
        }
      }
    }
  }
}
.funnel_image {
  height: 400px;
//   object-fit: contain;
  width: 100%;
}
