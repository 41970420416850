@import '../../../index.scss';
.navbutton_wrapper {
  height: 48px;
  width: 48px;
  border-radius: 0px;
  background-color: $buttonTextColor;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 32px;
  position: relative;
  box-shadow: #E3E6EC 0px 8px 24px;
  &:hover {
    cursor: pointer;
  }
}

.navbutton_badge {
  display: flex;
  color: $buttonTextColor;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 20px;
  width: 20px;
  right: -10px;
  top: 0px;
  border-radius: 24px;
  background: $iconBadgeBackground;
  font-family: 'Inter';
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}
