@import '../../index.scss';
.candidate_offered_modal_container {
  border-radius: 16px;
  padding: 16px !important;
  width: 450px;
  .candidate_offered_modal_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    border-radius: 16px;
    // width: 400px;
    width: 100%;
    border-radius: 24px;
    .candidate_offered_header {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      .candidate_offered_heading {
        font-family: 'Poppins';
        font-size: 24px;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: 0px;
      }
    }
    .candidate_offered {
      display: flex;
      gap: 10px;
      width: 100%;
      flex-direction: column;
      margin: 10px 0;
      .candidate_offered_modal_heading {
        font-family: 'Inter';
        font-size: 13px;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0px;
        text-align: left;
        padding-bottom: 10px;
      }
    }
    .candidate_offered_button_wrapper {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-top: 30px;
      gap: 20px;
      width: 100%;
    }
    .candidate_offered_button {
      width: 140px;
    }
  }
}
