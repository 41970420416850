@import '../../index.scss';
.candidate_history_container {
  margin: 20px;
  border-bottom: 1px solid $secondaryBtnColor;
  padding-bottom: 10px;
  .candidate_history_headings {
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 600;
    padding-bottom: 10px;
    &:hover {
      cursor: pointer;
    }
  }
  .candidate_role_content {
    font-family: 'Inter';
    font-size: 13px;
    font-weight: 400;
    color: $inputHeadingColor;
    padding-bottom: 10px;
  }
  .candidate_status_content {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
  }
}
.candidate_history_content_wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.candidate_history_content {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
