@import '../../../index.scss';

.multi-range-slider {
  box-shadow: none !important;
  border: none !important;
  z-index: 0 !important;
}
.multi-range-slider .bar-inner {
  background-color: $primaryLinkColor !important;
  box-shadow: none !important;
  border: none !important;
}
.multi-range-slider .thumb::before {
  content: '';
  background-color: $primaryLinkColor !important;
  position: absolute !important;
  width: 20px !important;
  height: 20px !important;
  border: none !important;
  box-shadow: none !important;
  border-radius: 50% !important;
  z-index: 1 !important;
  margin: -8px !important;
  cursor: pointer !important;
}
.multi-range-slider .bar-left {
  box-shadow: none !important;
}

.multi-range-slider .bar-right {
  box-shadow: none !important;
}
.multi-range-slider .thumb * {
  background-color: $primaryLinkColor !important;
  box-shadow: none !important;
  border: none !important;
}
