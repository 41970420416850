.view_resume_modal_close_icon {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  .view_resume_close {
    height: 30px;
    width: 30px;
  }
}
.view_resume_image{
  width:100%;
  height:600px;
  object-fit: contain;
}