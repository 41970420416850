@import '../../index.scss';

.offer_template_container {
  width: 100%;
  box-shadow: #e3e6ec 0px 8px 24px;
  border-radius: 24px;
  padding: 30px 0;
  // padding-bottom: 30px;
  margin-top: 10px;

  .header {
    margin-bottom: 30px;
    padding: 0 30px;
  }

  .offer_template_wrapper {
    .billing_container {
      padding: 0 30px;
      margin-bottom: 30px;

      .billing_wrapper {
        width: 650px;
        display: flex;
        flex-direction: column;
        gap: 25px;

        .billing_details {
          padding: 20px;
          border: 1px solid $borderColor;
          border-radius: 16px;
          display: flex;
          flex-direction: column;
          gap: 20px;

          .billing_invoice_list {
            max-height: 200px;
            overflow-y: scroll;
          }

          .billing_header {
            font-family: 'Poppins';
            font-weight: 500;
            color: #11142d;
            font-size: 20px;
            text-align: left;
          }

          .billing_create_date {
            font-family: Inter;
            font-size: 12px;
            font-weight: 400;
            line-height: 24px;

          }

          .bill_summary {
            display: flex;
            gap: 10px;
            flex-direction: column;
          }

          .update_plan {
            width: 150px;
            height: 56px
          }

          .sub_plan_cancel {
            display: flex;
            gap: 15px;
            align-items: center;

            .update_plan {
              width: 150px;
              height: 56px
            }

            .or {

              font-family: Inter;
              font-size: 14px;
              font-weight: 400;
            }

            .cancel_subscription {
              color: #1D7BDF;
              font-family: Inter;
              font-size: 14px;
              font-weight: 700;
              cursor: pointer;
            }

          }
        }

      }
    }

    .subscription_container {
      margin-top: 36px;

      .subscription_wrapper {

        .subscription_slider {
          display: flex;
          gap: 30px;
          padding: 0 30px;
          margin-bottom: 16px;
          align-items: center;

          .slider_header_value {
            display: flex;
            align-items: center;
            gap: 10px;

            .slider_name {
              font-family: Inter;
              font-size: 14px;
              font-weight: 400;
              line-height: 24px;
            }

            .slider_value {
              width: 35px;
              height: 35px;
              align-items: center;
              justify-content: center;
              display: flex;
              background: $secondaryBtnColor;
              border-radius: 8px;
              font-size: 14px;
              font-family: Inter;
              font-style: normal;
              font-weight: 600;
              line-height: 20px;
            }
          }

          .slider_wrapper {
            width: 600px;
            display: flex;
            flex-direction: column;
          }
        }

        .subscription_plan_container {
          display: flex;
          width: 100%;
          margin-left: 15px;
          // justify-content: space-between;

          .subscription_plan_wrapper {
            box-shadow: #e3e6ec 0px 8px 24px;
            border-radius: 24px;
            padding: 24px;
            margin: 13px;
            display: flex;
            flex-direction: column;
            width: 300px;
            gap: 10px;

            &.sub_plan_active {
              background: $gradientButtonColor !important;
            }

            .plan_color {
              color: $buttonTextColor !important;
            }

            .plan_bg {
              background: $buttonTextColor !important;
            }

            .subscription_plan_header {
              display: flex;
              gap: 20px;
              position: relative;
              padding: 10px 0 5px 0;


              .subscription_plan_icon {
                width: 50px;
                height: 50px;
                border-radius: 12px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: $paymentIconColor;
              }

              .plan_popular {
                position: absolute;
                border-radius: 8px;
                background: rgba(255, 255, 255, 0.2);
                width: 70px;
                height: 34px;
                align-items: center;
                justify-content: center;
                display: flex;
                color: #ffffff;
                font-size: 14px;
                font-family: DM Sans;
                font-style: normal;
                font-weight: 400;
                right: -6px;
                top: -8px;
              }

              .subscription_plan_content {
                margin-top: 2px;

                .company_plan {
                  font-family: DM Sans;
                  font-size: 16px;
                  font-weight: 500;
                  line-height: 20px;
                  letter-spacing: 0em;
                  text-align: left;
                  margin-bottom: 5px;
                  color: $LightKirMistTextColor;
                }

                .plan_name {
                  font-family: DM Sans;
                  font-size: 20px;
                  font-weight: 700;
                  letter-spacing: 0px;
                  text-align: left;
                  color: $ultramarineTextColor;
                }
              }
            }

            .subscription_desc {
              font-family: DM Sans;
              font-size: 14px;
              font-weight: 400;
              color: $LightKirMistTextColor;
            }

            .subscription_amount {
              display: flex;
              gap: 5px;

              .sub_amount {
                font-family: DM Sans;
                font-size: 48px;
                font-weight: 700;
                color: $ultramarineTextColor;

              }

              .sub_month {
                font-family: DM Sans;
                font-size: 18px;
                font-weight: 500;
                color: $LightKirMistTextColor;
                display: flex;
                align-items: flex-end;
                padding-bottom: 10px;

              }

            }

            .subscription_plan {
              display: flex;
              gap: 10px;
              flex-direction: column;

              .plan_header {
                font-family: DM Sans;
                font-size: 16px;
                font-weight: 700;
                color: $ultramarineTextColor;

              }

              .sub_many_plan {
                display: flex;
                flex-direction: column;
                gap: 8px;

                .sub_plan {
                  display: flex;
                  gap: 10px;
                  align-items: center;

                  .plan_name {
                    font-family: DM Sans;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    color: $ultramarineTextColor;
                  }
                }
              }
            }

            .contact_us {
              height: 100%;
              justify-content: flex-end;
              flex-direction: column;
              // align-items: flex-end;
              display: flex;
            }

            .subscription_btn {
              margin-top: 20px;

              .button {
                background: $gradientButtonColor;
              }
            }


          }
        }

      }
    }
  }


}