@import '../../index.scss';
.landing_container {
  margin: 20px;
  box-shadow: #e3e6ec 0px 8px 24px;
  border-radius: 24px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  .job_landing_wrapper {
    width: 90%;
    .landing_header {
      padding-bottom: 20px;
      border-bottom: 1px solid $secondaryBtnColor;
      width: 50%;
      .landing_header_main {
        font-family: 'Poppins';
        font-size: 40px;
        font-weight: 600;
      }
      .landing_header_sub {
        font-family: 'Poppins';
        font-size: 14px;
        font-weight: 500;
      }
    }
    .landing_wrapper {
      display: flex;
      padding-top: 20px;
      .landing_main_container {
        width: 60%;
        padding-right: 30px;
        .landing_content1_container {
          // font-family: 'Inter';
          font-size: 14px;
          // font-weight: 700;
          line-height: 20px;
          letter-spacing: 0px;
          padding-top: 10px;
          padding-bottom: 10px;
        }
        .landing_content2_container {
          font-family: 'Inter';
          font-size: 14px;
          font-weight: 400;
          padding-bottom: 30px;
          line-height: 20px;
        }
        .landing_subheading {
          font-family: 'Inter';
          font-size: 14px;
          font-weight: 600;
          line-height: 20px;
          padding-bottom: 10px;
        }
        .landing_heading {
          font-family: 'Poppins';
          font-size: 14px;
          font-weight: 500;
          line-height: 24px;
          padding-bottom: 10px;
        }
        .landing_content,
        .landing_list_container {
          padding-left: 10px;
        }
        .landing_position_purpose_container {
          padding-top: 10px;
        }
        .landing_responsibilities_container {
          padding-top: 30px;
          padding-bottom: 30px;
        }
        .landing_have_container {
          padding-top: 30px;
          padding-bottom: 30px;
        }
      }
      .landing_sub_container {
        width: 35%;
        border-left: 1px solid $secondaryBtnColor;
        padding-left: 30px;
        .landing_sub_button {
          border-bottom: 1px solid $secondaryBtnColor;
          padding-bottom: 30px;

          display: flex;
          justify-content: center;
          .top_apply_button {
            width: 75%;
          }
        }
        .landing_sub_wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 20px;
          padding-top: 30px;
          .landing_sub_recruiter {
            font-family: 'Poppins';
            font-size: 14px;
            font-weight: 500;
          }
          .landing_recruiter_name {
            font-family: 'Inter';
            font-size: 14px;
            font-weight: 400;
          }
          .landing_recruiter_position {
            font-family: 'Inter';
            font-size: 13px;
            font-weight: 400;
          }
        }
      }
    }
    .landing_footer {
      padding-top: 30px;
      width: 20%;
    }
  }
}
.landing_sub_image {
  width: 80px;
  height: 80px;
  border-radius: 100%;
}
.landing_recruiter_image {
  width: 80px;
  height: 80px;
  border-radius: 100px;
  object-fit: cover;
}
.landing_content1_container > p,
h2,
li {
  line-height: 25px;
}
@media only screen and (max-width: 768px) {
  .landing_header {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .landing_wrapper {
    display: flex;
    flex-direction: column;
  }
  .landing_main_container {
    width: 100% !important;
  }
  .landing_sub_container {
    width: 100% !important;
    border: none !important;
    padding: 0px !important;
  }
  .landing_header_sub {
    text-align: center;
  }
  .landing_footer {
    width: 100% !important;
    display: flex;
    justify-content: center;
    .bottom_apply_button {
      width: 75%;
    }
  }
}
.landing_video_container{
  width:'100%';
  display: flex;
  justify-content: flex-start;
  align-items: center;
}